import React from "react"
import styled from "styled-components"
import { UserQuestionAnswer } from "../types"
import { faFlag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


interface StyledTakeTestCompassProps {
  showCompass: boolean
}
const StyledTakeTestCompass = styled.div`
  ${(props: StyledTakeTestCompassProps) =>
    !props.showCompass && 'display: none;'
  }

  position: absolute;
  bottom: calc(100% + 1px);
  right: 0;

  padding:  1rem .25rem;
  background-color: #016BAC;

  >div:nth-child(1){
    padding: 0 1rem
  }

  >div:nth-child(2){
    margin-top: 1rem;
    min-height: 200px;
    max-height: 500px;
    overflow-y: scroll;
    background-color: #FFF;

    >div:nth-child(2n){
      background-color: #F4F4F4;
    }
  }
`

interface TakeTestCompassProps {
  questions: UserQuestionAnswer[]
  showCompass: boolean
  goToQuestion: Function

}

export const TakeTestCompass = (props: TakeTestCompassProps) => {
  return (
    <StyledTakeTestCompass showCompass={props.showCompass}>
      <div>
        Compass - Select a question to go to it
      </div>
      <div>
        {props.questions.map((question, i) => (
          <TakeTestCompassLine
            key={`${question.questionId}-${i}`}
            question={question}
            goToQuestion={props.goToQuestion}
          />
        ))}
      </div>
    </StyledTakeTestCompass>
  )
}

interface StyledTakeTestCompassLineProps {
  highlighted: boolean
  status: string
}

const StyledTakeTestCompassLine = styled.div`
  display: flex;

  color: black;
  ${(props: StyledTakeTestCompassLineProps) =>
    props.highlighted && 'background-color: yellow !important;'
  }

  >div:nth-child(1){
    width: 45%;
    color: blue;
    cursor: pointer;
    &:hover{
      text-decoration: underline;
    }
  }

  >div:nth-child(2){
    width: 45%;
    color: ${(props: StyledTakeTestCompassLineProps) =>
      props.status === 'Completed' ? 'black' : 'red'
    };
  }

  >div:nth-child(3){
    width: 10%;
  }

`

interface TakeTestCompassLineProps {
  question: UserQuestionAnswer
  goToQuestion: Function
}

const TakeTestCompassLine = (props: TakeTestCompassLineProps) => {
  const question = props.question
  let status
  if (!question.seen) {
    status = 'Unseen'
  } else if (question.answer === '') {
    status = 'Unanswered'
  } else {
    status = 'Completed'
  }

  return (
    <StyledTakeTestCompassLine status={status} highlighted={question.highlighted}>
      <div onClick={() => props.goToQuestion(question.questionId)}>Question {question.name}</div>
      <div>{status}</div>
      <div>{question.flagged && <FontAwesomeIcon icon={faFlag} />}</div>
    </StyledTakeTestCompassLine>
  )
}