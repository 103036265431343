import React, { useContext, useState, useEffect } from "react";
import { Flashcard, StoreConextInterface } from "../types";
import styled from "styled-components";
import { SelectDropdown, Button, deviceSize, largeText, veryLargeText, StyledHr } from "./common";
import { StoreContext } from "../contexts/storeContext";
import { FLASHCARD_ONLY, FULL_ACCESS, ROUTES, TESTS_AND_FLASHCARDS, TEST_ONLY } from "../constants";
import { Navigate } from "react-router-dom";
import { sortCategories } from "../helpers/structures";

const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 60px 0 120px;

  @media ${deviceSize.tablet} {
    width: 100% important!;
    padding: 30px 10px 60px;
  }
`;

const StyledFlashcardsSection = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  font-size: 56px;
  text-align: center;
  vertical-align: middle;
  justify-content: center;
  font-size: ${veryLargeText};
  font-weight: 300;
  line-height: 1.1;
  flex: 1;
  box-shadow: 5px 5px 5px grey;
  border: 1px solid grey;
  padding: 20px;

  @media ${deviceSize.mobileL} {
    font-size: ${largeText};
  }

  > div:first-of-type {
    align-content: center;
    justify-content: space-between;
    width: 100%;
  }

  li {
    text-align: left;
  }
`;

const StyledLink = styled.span`
  margin-left: 1rem;
  align-self: center;

  a {
    font-weight: 400;
    color:#016BAC;
    text-decoration: none;
    text-transform: uppercase;

    &:hover{
      text-decoration: underline;
    }
  }

`;

const StyledButtonBar = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0;
  width: 80%;
  position: fixed;
  bottom: 0;
  flex-wrap: wrap;

  select {
    max-width: 100%;
    &:focus {
      outline: none;
      width:auto;
    }
  }

  .container{
    max-width: 100%;
    display: flex;
    justify-content: center;
  }

  @media ${deviceSize.mobileL} {
    flex-wrap: wrap;
  }

`;

const ALL = "All";
export const Flashcards = () => {
  const { testsFlashcards, access } = useContext<StoreConextInterface>(StoreContext);
  const [flashcards, setFlashcards] = useState<Flashcard[]>(testsFlashcards && testsFlashcards.flashcards);
  const [topics, setTopics] = useState<string[]>([
    ALL,
    ...Array.from(new Set(sortCategories(testsFlashcards.flashcards.map(f => f.t)))),
  ]);
  const [index, setIndex] = useState<number>(0);
  const [topic, setTopic] = useState<string>(ALL);
  const [showAnswer, setShowAnswer] = useState<boolean>(false);

  if (topics.length !== new Set(sortCategories(testsFlashcards.flashcards.map(f => f.t))).size + 1) {
    setTopics([
      ALL,
      ...Array.from(new Set(sortCategories(testsFlashcards.flashcards.map(f => f.t)))),
    ])
  }

  const updateIndex = (newIndex: number) => {
    setIndex(newIndex);
    setShowAnswer(false);
  };

  useEffect(() => {
    setShowAnswer(false);
    setFlashcards(
      topic === ALL ? testsFlashcards.flashcards : testsFlashcards.flashcards.filter(f => f.t === topic),
    );
    setIndex(0);
  }, [topic, testsFlashcards.flashcards]);
  let a = "";
  let q = "";
  if (flashcards[index]) {
    a = flashcards[index].a;
    q = flashcards[index].q;
  }

  const renderHTML = (htmlContent: string) => {
    return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
  };


  if (access === TEST_ONLY) return <Navigate to={ROUTES.TESTS} />
  else if (access === FLASHCARD_ONLY || access === FULL_ACCESS || access === TESTS_AND_FLASHCARDS) {
    return (
      <StyledSection>
        <StyledFlashcardsSection>
          <div>{renderHTML(q)}</div>
          <StyledHr />
          {showAnswer && <div>{renderHTML(a)}</div>}
        </StyledFlashcardsSection>
        <StyledButtonBar>
          <div className="container">
            <SelectDropdown defaultValue={topic} options={topics} onChange={(value: string) => setTopic(value)} />
          </div>
          <div className="container">
            <Button disabled={index === 0} onClick={() => (index > 0 ? updateIndex(index - 1) : null)}>
              Back
            </Button>
            <Button
              selected={showAnswer}
              onClick={() => setShowAnswer(!showAnswer)}>{showAnswer ? `Hide Answer` : `Show Answer`}</Button>
            <Button
              disabled={index === flashcards.length - 1}
              onClick={() => (index < flashcards.length - 1 ? updateIndex(index + 1) : null)}
            >{(index === flashcards.length - 1) ? "Done" : "Next"}</Button>
          </div>
          <div className="container">
            <StyledLink>{<a rel="noopener noreferrer" target="_blank" href={require('../assets/extra/Flashcards.pdf')}>Download PDF to print flashcards</a>}</StyledLink>
          </div>
        </StyledButtonBar>
      </StyledSection>
    );
  } else {
    return <></>
  }
};
