import React from "react";
import styled from "styled-components";

const StyledLoginPageInfo = styled.div`
  a{
    color: #3d548a;
    display: inline-block;
    text-decoration: none;
    transition: opacity .2s ease-out;
    
    &:hover{
      color: #0056b3;
      text-decoration: underline;
    }
  }

  >div:nth-of-type(1){
    >a{
      font-size: 1.3em;
      line-height: 1em;
    }
  }
`;

export const LoginPageInfo = () => {
  return (
    <StyledLoginPageInfo>
      <div>
        <a href={'https://www.cbprepcourse.com/products/live-seminar'}>
          More information on our Live Seminar (in person or via the internet)
            </a>
      </div>
      <div>
        <p>
          This is the CBPrepcourse Student's site. For more
          information about our program go
              to <a href={'https://www.CBPrepcourse.com'}>www.CBPrepcourse.com</a>.
              We would love to have you as one of our students.
              Get started anytime and then attend our live seminar in person
              (Laredo, Texas) or broadcast via the internet. Our seminars are offered
              twice a year in October and April to correspond with the
              Customs Broker exam.  The seminar is three days; Thursday, Friday
              and Saturday 8:30 to 5:00.  
        </p>
        <p>
          *CFR and HTS sold separately*
            </p>
      </div>
    </StyledLoginPageInfo>
  )
}
