import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { ROUTES } from "../constants";
import { StoreContext } from "../contexts/storeContext";

export const AuthenticatedRoute: React.FC = () => {
  const { loggedInUser } = useContext(StoreContext);
  
  if (!loggedInUser) {
    return <Navigate to={ROUTES.HOME} replace />;
  }
  
  return <Outlet />;
};

export default AuthenticatedRoute;