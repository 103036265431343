import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import styled from "styled-components"
import ppLogo from '../assets/pplogo_small.gif'
import { FLASHCARD_ONLY, FULL_ACCESS, ROUTES, TESTS_AND_FLASHCARDS, TEST_ONLY } from '../constants'
import { StoreContext } from '../contexts/storeContext'
import { StoreConextInterface } from '../types'
import { AppTitle, ContentBox } from './common'

interface notesListInterface {
  name: string
  chapter: string
  fileName: string
}

const notesList: notesListInterface[] = [
  { name: 'Welcome', chapter: '-', fileName: 'WelcomeIntroduction.pptx' },
  { name: 'CFR 101', chapter: '1', fileName: 'Ch.1and2.pptx' },
  { name: 'Basic Entry & Entry Summary', chapter: '2', fileName: 'Ch.1and2.pptx' },
  { name: 'Basic Entry Requirements', chapter: '3', fileName: 'Ch.3.pptx' },
  { name: 'Special Entry Procedures', chapter: '4', fileName: 'Ch.4.pptx' },
  { name: 'Bonds', chapter: '5', fileName: 'Ch.5.pptx' },
  { name: 'Liquidations and Currency Conversion', chapter: '6', fileName: 'Ch.6.pptx' },
  { name: 'GO, Duty Refund', chapter: '7', fileName: 'Ch.7.pptx' },
  { name: 'CFR 24 money stuff', chapter: '8', fileName: 'Ch.8.pptx' },
  { name: 'Valuation & Appraisement', chapter: '9', fileName: 'Ch.9.pptx' },
  { name: 'Customs Brokers', chapter: '10', fileName: 'Ch.10.pptx' },
  { name: 'ITs - in Bonds', chapter: '11', fileName: 'Ch.11.pptx' },
  { name: 'Prohibited & Restricted Goods', chapter: '12', fileName: 'Ch.12.pptx' },
  { name: 'Quota', chapter: '13', fileName: 'Ch.13.pptx' },
  { name: 'Tradename, Trademark and Copyright', chapter: '14', fileName: 'Ch.14.pptx' },
  { name: 'Country of Origin', chapter: '15', fileName: 'Ch.15.pptx' },
  { name: 'Marking', chapter: '16', fileName: 'Ch.16.pptx' },
  { name: 'Warehouse Entries, Bonded Warehouses & Proprietors', chapter: '17', fileName: 'Ch.17.pptx' },
  { name: 'AD CV', chapter: '18', fileName: 'Ch.18ADCV.pptx' },
  { name: 'USMCA', chapter: '19', fileName: 'Ch.19.pptx' },
  { name: 'Rulings and Correcting Errors', chapter: '20', fileName: 'Ch.20.pptx' },
  { name: 'Protest', chapter: '21', fileName: 'Ch.21.pptx' },
  { name: 'Petitions for Relief and Fines', chapter: '22', fileName: 'Ch.22.pptx' },
  { name: 'Foreign Trade Zone', chapter: '23', fileName: 'Ch.23.pptx' },
  { name: 'Drawback', chapter: '24', fileName: 'Ch.24.pptx' },
  { name: 'Record Keeping', chapter: '25', fileName: 'Ch.25.pptx' },
  { name: 'Carnets', chapter: '26', fileName: 'Ch.26.pptx' },
  { name: 'Trade Programs Introduction', chapter: '28', fileName: 'TRADEPROGRAMSprimer.pptx' },
  { name: 'Trade Programs 35 percent  framework', chapter: '28', fileName: 'Ch28GSPtype.pptx' },
  { name: 'Trade Programs USMCA and Tariff Shift framework', chapter: '28', fileName: 'Ch.28TradeProgramsandTradeAgreements.pptx' },
  { name: 'HTS Ch.98', chapter: '29', fileName: 'Ch.29HTS98.pptx' },
  { name: 'TIB - Temporary Import Under Bond', chapter: '29', fileName: 'Ch.29TIB.pptx' },
  { name: 'Textiles', chapter: '30', fileName: 'Ch.30.pptx' },
]

const StyledClassNotes = styled.div`
  img{
    height: 18px;
  }
`

const StyledClassNotesTable = styled.table`
  width: 100%;
  text-align: left;
  t
  th{
    font-size: 24px;
    padding-bottom: 1rem;
  }
`

export const ClassNotes = () => {
  const { access } = useContext<StoreConextInterface>(StoreContext)

  if (access === TEST_ONLY || access === TESTS_AND_FLASHCARDS) return <Navigate to={ROUTES.TESTS} />
  else if (access === FLASHCARD_ONLY) return <Navigate to={ROUTES.FLASHCARDS} />
  else if (access === FULL_ACCESS) {
    return (
      <div>
        <AppTitle
          title='CBPrepCourse Class Notes'
          description=''
        />
        <ContentBox>
          <StyledClassNotes>
            <p>
              Notes are in power point format. I use these for my lectures.
              These have been updted for the USMCA changes.
            </p>
            <p>
              All of these presentations are viewable in <a
                target='_blank'
                rel="noopener noreferrer"
                href="https://www.microsoft.com/en-us/microsoft-365/powerpoint"
              >Microsoft PowerPoint</a> or <a
                target='_blank'
                rel="noopener noreferrer"
                href="https://www.microsoft.com/en-us/download/#QuickInfoContainer"
              >Microsoft PowerPoint Viewer</a> <img src={ppLogo} alt="pplogo" />
            </p>
            <StyledClassNotesTable>
              <thead>
                <tr>
                  <th>Chapter</th>
                  <th>Topic</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {notesList.map(note => (
                  <tr key={note.chapter}>
                    <td>{note.chapter}</td>
                    <td>{note.name}</td>
                    <td>
                      <a
                        rel="noopener noreferrer"
                        download={note.fileName}
                        target="_blank"
                        href={require(`../assets/notes/${note.fileName}`)}
                      >Download</a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </StyledClassNotesTable>
          </StyledClassNotes>
        </ContentBox>
      </div>
    )
  } else {
    return <></>
  }
}
