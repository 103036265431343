//create react component to show processbar of test

import React from 'react';
import styled from 'styled-components';
import { almostWhite, takeTestDarkBlue } from './common';

type StyledProgressBarProps = {
  progress: number
}

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  width: 50%;
  margin: 0.5rem;
`
const StyledProgress = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const StyledProgressBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 0;
  border: 1px solid #81AEE1;
  border-radius: 5px;
  height: 1rem;
  width: 100%;

  >div:nth-child(1){
    font-weight: 500;
    font-size: 1.5rem;
    background: ${takeTestDarkBlue};
    width: ${(props: StyledProgressBarProps) => props.progress ? `${props.progress}%` : 0};
    height: 1rem;
  }
  >div:nth-child(2){
    font-weight: 500;
    font-size: 1.5rem;
    background: ${almostWhite};
    width: ${(props: StyledProgressBarProps) => props.progress ? `${100 - props.progress}%` : 0};
    height: 1rem;
  }
`

interface ProgressBarProps {
  currentQuestionIndex: number
  maxQuestions: number
}

export const TestProgress = ({ currentQuestionIndex, maxQuestions }: ProgressBarProps) => {
  const progress = ((currentQuestionIndex + 1) / maxQuestions) * 100;

  return (
    <StyledContainer>
      <StyledProgress>
        <span>Progress</span>
        <StyledProgressBar progress={progress}>
          <div></div>
          <div></div>
        </StyledProgressBar>
      </StyledProgress>
    </StyledContainer>
  )
}