import moment from "moment"
import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { SKUS } from "../constants"
import { FirebaseContext } from "../contexts/firebaseContext"
import { StoreContext } from "../contexts/storeContext"
import { CbprepSku, UserPurchases } from "../types"
import { AdminUsersAddNew } from "./admin-users-add-new"
import { AdminUsersListItem } from "./admin-users-list-item"
import { buttonColor } from './common'

export interface UserSkuData {
  moduleName: CbprepSku;
  expiration: Timestamp;
}
export interface Timestamp {
  seconds: number;
  nanoseconds: number;
}
export interface User {
  email: string;
  module: UserSkuData;
}


const StyledAdminUsers = styled.div`
  table {
    width: 100%;
    text-align: left;

    border-collapse: collapse;
    border-spacing: 0;
    
    tr{
      th{
        padding-top: 11px;
        padding-bottom: 11px;
        background-color: ${buttonColor};
        color: white;
        padding: 8px;
        border: 1px solid #ddd;
      }

      td{
        text-align: center;
        padding: 8px;
        border: 1px solid #ddd;

        &:first-of-type{
          text-align: left;
        }

        >input{
          width: -webkit-fill-available;;
        }
      }

      &:nth-child(even){
        background-color: #f2f2f2;
      }
    }

    &:first-of-type{
      margin-bottom:20px;
    }
  }
`

export const AdminUsers = () => {
  const { firebase } = useContext(FirebaseContext);
  const { setError } = useContext(StoreContext);
  const [fireUsersList, setFireUsersList] = useState<UserPurchases[]>([]);
  const [cbpUsers, setCbpUsers] = useState<User[]>([]);

  const currentYear = moment().year()
  const expirationDates: number[] = [
    moment(`04/30/${currentYear}`, "MM/DD/YYYY").unix(),
    moment(`10/31/${currentYear}`, "MM/DD/YYYY").unix(),
    moment(`04/30/${currentYear + 1}`, "MM/DD/YYYY").unix(),
    moment(`10/31/${currentYear + 1}`, "MM/DD/YYYY").unix()
  ]

  const updateUserPurchasesListFirebase = (user: UserPurchases, callback?: Function) => {
    firebase.doUpdateUserPurchases(user, (e: Error) => {
      if (e) {
        console.error(e);
        setError(e.message);
      }
      setFireUsersList([]);
      if(callback) callback()
    });
  }

  const updateUserExpirationDate = (email: string, cbpSku: string, newExpTimestamp: number, callback: Function) => {
    if (!email) return;
    let userIndex = fireUsersList.findIndex(u => u.email === email);
    let user = fireUsersList[userIndex]

    user[cbpSku] = {
      expiration: {
        seconds: newExpTimestamp,
        nanoseconds: 0,
      }
    }

    updateUserPurchasesListFirebase(user, callback)
  }

  const updateUserCbpSku = (email: string, newSku: string, currentSku: string, callback: Function) => {

    if (!email) return;
    let userIndex = fireUsersList.findIndex(u => u.email === email);
    let user = fireUsersList[userIndex] as any

    const currentExp = user[currentSku].expiration
    // remove current cbprep sku
    user[currentSku] !== undefined && delete user[currentSku]

    user = { ...user, [newSku]: { expiration: currentExp } };

    updateUserPurchasesListFirebase(user, callback)
  };

  const addNewUser = (email: string, sku: string, expTimestamp: number) => {
    let user = fireUsersList.find(u => u.email === email);
    const expiration = { seconds: expTimestamp, nanoseconds: 0 }

    if (user) {
      console.log('user found')
      setError(`User already Exists, ${email}`)
      if (user[sku]) {
        console.log('user already has this sku')
        // setInfo(`User with that Email already has SKU: ${sku} assigned.`)
        return
      } else {
        // delete any existing cbp-sku in the user
        SKUS.forEach(sku => {
          user && user[sku] !== undefined && delete user[sku]
        });
        user[sku] = { expiration }
      }

      updateUserPurchasesListFirebase(user)
    } else {
      firebase.doAddNewUserPurchases({ email, [sku]: { expiration } }, (e: Error) => {
        if (e) setError(e.message);
        setFireUsersList([]);
      });
    }
  };

  useEffect(() => {
    if (fireUsersList.length === 0) {
      try {
        firebase.doRetrieveAllUserIds((fireUsers: UserPurchases[]) => {
          if (!fireUsers) console.log("no user ids retrieved");
          setFireUsersList(fireUsers)
        });
      } catch (e) {
        console.error("could not retrieve users", e);
      }
    } else {
      const userModuleData = fireUsersList.reduce((p: User[], fireUser: UserPurchases) => {
        let user
        Object.keys(fireUser).forEach((key: string) => {
          if (key !== "email") {
            const userSku = key as CbprepSku;
            if (SKUS.includes(userSku)) {
              const exp = fireUser[key] as UserSkuData;
              const expiration = exp.expiration as Timestamp;
              user = { email: fireUser.email, module: { moduleName: key, expiration } } as User;
            }
          }
        });
        if (user !== undefined) {
          return [...p, user]
        } else {
          return p
        }
      }, []).sort((a, b) => a.email.toLowerCase() > b.email.toLowerCase() ? 1 : -1);
      
      setCbpUsers(userModuleData);
    }
  }, [firebase, fireUsersList]);

  return (
    <StyledAdminUsers>
      <AdminUsersAddNew
        addNewUser={addNewUser}
        expirationDates={expirationDates}
      />
      <table>
        <thead>
          <tr>
            <th>{`Email (${cbpUsers.length})`}</th>
            <th>SKU</th>
            <th>Expiration</th>
            <th></th>
            <th>Reset Password</th>
            <th>Delete User</th>
          </tr>
        </thead>
        <tbody>
          {cbpUsers.map((u, i) => (
            <AdminUsersListItem
              key={i}
              user={u}
              updateUserCbpSku={updateUserCbpSku}
              updateUserExpirationDate={updateUserExpirationDate}
              expirationDates={expirationDates}
            />
          ))}
        </tbody>
      </table>
    </StyledAdminUsers>
  )
}
