import React, { useContext, useState } from "react";
import styled from "styled-components";
import { StoreContext } from "../contexts/storeContext";
import useErrorHandler from "../hooks/error-handler";
import { Button } from "./common";
import { validateLoginForm } from "../helpers/validators";
import * as validator from "validator";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../constants"

const StyledMessage = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
`

const StyledLoginForm = styled.div`
  >div{
    margin-bottom: 16px;
  }

  > div:nth-of-type(1){
    font-size: 15px;
    color: #333;
    // font-weight: 300;
    font-style: normal;
    text-transform: none;
    letter-spacing: 0px;
    line-height: 1.15em;
  }

  > div:nth-of-type(2){
    color: red;
    font-size: small;
  }

  > div:nth-of-type(3){
    margin-bottom: 8px;
    > input {
      padding: .5rem 1rem;
      font-size: 1.25rem;
      line-height: 1.5;
      border-radius: .3rem;
      width: -webkit-fill-available;
      height: 25px;
      color: #495057;
      background-color: #fff;
      border: 1px solid rgba(0,0,0,.15);
      transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;

      &:focus {
        color: #495057;
        background-color: #fff;
        border-color: #80bdff;
        outline: 0;
      }      

      &:first-of-type{
        margin-bottom: 16px;
      }
    }
  }

  > div:nth-of-type(4){
    > button:first-of-type {
      width: auto;
      color: #fff;
      background-color: #28a745;
      border-color: #28a745;
      padding: .5rem 1rem;
      font-size: 1.25rem;
      line-height: 1.5;
      border-radius: .3rem;
      transition: all .15s ease-in-out;
      user-select: none;
      border: 1px solid transparent;
      display: inline-block;
      font-weight: 400;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      
      &:hover {
        background-color: #218838;
        border-color: #1e7e34;
      }

      &:focus {
        box-shadow: 0 0 0 3px rgb(40 167 69 / 50%);
        outline: 0;
      }
    }
  }
  >button:last-of-type{
    margin-bottom: 8px;
    cursor: pointer;
    background: none;
    border: none;
    color: blue;
    padding: 0;

    &:disabled {
      color: grey;
      cursor: default;
    }

  }
`;

export const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { loginUser, loginError, sendPasswordResetEmail } = useContext(StoreContext);
  const { error, showError } = useErrorHandler(null);
  const [sentMessage, setSentMessage] = useState(false);
  const navigate = useNavigate();
  return (
    <StyledLoginForm>
      <div>
        Your username is the email we have on file. Contact us if you are unable to login in. 512-441-9757.
      </div>
      <div>
        <span>{error || loginError}</span>
      </div>
      <div>
        <input
          name="email"
          value={email}
          onChange={(e: any) => setEmail(e.target.value)}
          type="text"
          placeholder="Email Address"
        />
        <input
          name="password"
          value={password}
          onChange={(e: any) => setPassword(e.target.value)}
          type="password"
          placeholder="Password"
          onKeyUp={(e: any) => {
            e.preventDefault();
            if (e.keyCode === 13) {
              if (validateLoginForm(email, password, showError)) {
                navigate(
                  ROUTES.DASHBOARD
                )
                loginUser(email, password);
              }
            }
          }}
        />
      </div>
      <button disabled={!validator.isEmail(email)} onClick={() => {
        sendPasswordResetEmail(email)
        setSentMessage(true)
      }
      }>
        <span>Click here to reset or set up new password</span>
        <span> An email will be sent (please check your spam folder)</span>
      </button>
      {sentMessage && <StyledMessage>Password Reset Email was sent</StyledMessage>}
      <div>
        <Button
          onClick={(e: any) => {
            e.preventDefault();
            if (validateLoginForm(email, password, showError)) {
              navigate(
                ROUTES.DASHBOARD
              )
              loginUser(email, password);
            }
          }}
        >
          Login
        </Button>
      </div>
    </StyledLoginForm>
  )
}
