import {
    UserModuleTestInfo,
    AccountContextInterface,
  } from "../types";
  import { useContext, useState } from "react";
  import React from "react";
  import { FirebaseContext } from "./firebaseContext";
  import { deepClone } from "../helpers/structures";
import { VIDEOS } from "../video-links";
  
  export const AccountContext = React.createContext<AccountContextInterface>({
    userModuleTestInfo: {},
    getUserTestData: () => { },
    userId: "",
    loaded: false,
    videoCount: 0,
  });
  
  interface AccountProviderProps {
    children: React.ReactNode;
  }
  
  function AccountProvider(props: AccountProviderProps) {
    const { firebase } = useContext(FirebaseContext);
    const [userId, setUserId] = useState<string>("");
    const [storedUserData, setStoredUserData] = useState<UserModuleTestInfo>({});
  
    const getUserTestData = (userId: string, callback: Function) => {
      setUserId(userId);
      try {
        firebase.doRetrieveUserData(userId, (data: object) => {
          const userModuleTestInfo = data as UserModuleTestInfo;
          const userData = deepClone<UserModuleTestInfo>(userModuleTestInfo);
          setStoredUserData(userData);
          if (callback) callback(null, userData);
        });
      } catch (e) {
        if (callback) callback(`User's data could not be retrieved, ${e}`);
        console.error(e)
      }
    };

    const userItems = {
      getUserTestData,
      userModuleTestInfo: storedUserData,
      loaded: Object.keys(storedUserData).length > 0,
      userId,
      videoCount: VIDEOS.reduce((acc, curr) => acc + curr.videos.length, 0),
    };
  
    return <AccountContext.Provider value={userItems}>{props.children}</AccountContext.Provider>;
  }
  
  export default AccountProvider;
  