import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    .react-calendar-heatmap .color-scale-1 { fill: #d6e685; }
    .react-calendar-heatmap .color-scale-2 { fill: #8cc665; }
    .react-calendar-heatmap .color-scale-3 { fill: #44a340; }
    .react-calendar-heatmap .color-scale-4 { fill: #1e6823; }

    @media print {
        @page {
            size: auto;
            margin: 20mm;
        }

        html, body {
            width: 100% !important;
            height: auto !important;
            margin: 0 !important;
            padding: 0 !important;
            overflow: visible !important;
        }

        body {
            -webkit-print-color-adjust: exact !important;
            print-color-adjust: exact !important;
        }

        .PrintContainer {
            display: block !important;
            width: 100% !important;
            max-width: 100% !important;
            height: auto !important;
            overflow: visible !important;
        }

        /* Hide the footer and all buttons when printing */
        footer, .footer, [data-testid="test-footer"],
        button, .button, [role="button"] {
            display: none !important;
        }

        /* Ensure all content in PrintContainer is visible */
        .PrintContainer * {
            visibility: visible !important;
            overflow: visible !important;
        }

        /* Reset any fixed widths or margins */
        #root, #__next, main, div {
            width: auto !important;
            max-width: none !important;
            margin: 0 !important;
            padding: 0 !important;
        }
    }
`;

export default GlobalStyle;