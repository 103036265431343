import React, { useContext, useState } from 'react'
import { Question, TestContextInterface } from '../types'
import { AdminTestQuestionsItem } from './admin-test-questions-item'
import { Test } from '../types';
import { FirebaseContext } from "../contexts/firebaseContext"
import { TestContext } from '../contexts/testContext';
import { Button } from './common';

interface AdminTestQuestionsContainerProps {
  test: Test
  type: string
  isProcessing: boolean
  setIsProcessing: Function
}
export const AdminTestQuestionsContainer = (
  { test, type, isProcessing, setIsProcessing }: AdminTestQuestionsContainerProps) => {
  const { firebase } = useContext(FirebaseContext);
  const { loadTestsFlashcards } = useContext<TestContextInterface>(TestContext)

  const [addingQuestion, setAddingQuestion] = useState<boolean>(false)

  const doSaveQuestionChanges = (questionData: Question, questionIndex: number, newQuestion: boolean, callback?: Function) => {
    if (test) {
      var newTestData = { ...test }
      if (newQuestion) {
        let foundName = false
        let potentialName = test.questions.length + 1
        while (!foundName) {
          const exisitingQuestionWithNameIndex = test.questions.findIndex(x => parseInt(x.name) === potentialName)
          if (exisitingQuestionWithNameIndex >= 0) potentialName++
          else {
            const exisitngDetailForNameIndex = test.details.findIndex(x => x.startQuestion <= potentialName && x.endQuestion >= potentialName)
            if (exisitngDetailForNameIndex >= 0) potentialName++
            else foundName = true
          }
        }

        questionData.name = potentialName.toString()
        questionData.id = parseInt(test.id + potentialName.toString())
        questionData.testId = test.id

        newTestData.questions = [...newTestData.questions, questionData]
      } else {
        newTestData.questions[questionIndex] = questionData
      }

      try {
        firebase.doUpdateTest(type, newTestData, () => {
          loadTestsFlashcards(() => {
            if (addingQuestion) setAddingQuestion(false)
            if (callback) callback()
          })
        })
      } catch (error) {
        console.log('Error saving question changes')
        if (callback) callback()
      }
    }
  }

  const doMoveQuestionUp = (questionData: Question, questionIndex: number, callback?: Function) => {
    // move the question up, if question is at index 0 don't do anything
    if (test) {
      var newTestData = { ...test }
      // move question to top
      const questions = newTestData.questions.filter((q, i) => i !== questionIndex)
      newTestData.questions = [questionData, ...questions]
      try {
        firebase.doUpdateTest(type, newTestData, () => {
          loadTestsFlashcards()
        })
      } catch (error) {
        console.log('Error saving question changes')
        if (callback) callback()
      } finally {
        setIsProcessing(false)
      }
    }
  }
  const doDeleteQuestion = (questionIndex: number, callback: Function) => {
    let newTestData = { ...test } as Test
    const [{ name }] = newTestData.questions.splice(questionIndex, 1)
    // check if question has a detail just for that question, if so remove detail too
    const detailIndex = newTestData.details.findIndex(x =>
      x.startQuestion.toString() === name &&
      x.endQuestion.toString() === name
    )
    if (detailIndex >= 0) newTestData.details.splice(detailIndex, 1)

    try {
      firebase.doUpdateTest(type, newTestData, () => {
        setIsProcessing(false)
        loadTestsFlashcards()
      })
    } catch (error) {
      console.log('Error deleting question')
      callback()
    }
  }

  return (
    <div className='item-container'>
      <div>
        <Button
          disabled={isProcessing}
          secondary
          onClick={() => setAddingQuestion(!addingQuestion)}
        >Add Question</Button>
      </div>
      <div>

        {addingQuestion &&
          <AdminTestQuestionsItem
            question={undefined}
            testType={type}
            testName={test.categoryName !== null ? test.categoryName : test.name}
            doSaveQuestionChanges={(newQuestion, callback) => doSaveQuestionChanges(newQuestion, -1, true, callback)}
            isProcessing={isProcessing}
            setIsProcessing={setIsProcessing}
          />
        }
        {test && test.questions.map((question: Question, idx) => {
          const numberName = parseInt(question.name)
          return (
            <AdminTestQuestionsItem
              key={`${idx}_${question.id}`}
              questionIndex={idx}
              question={question}
              testType={type}
              testName={test.categoryName !== null ? test.categoryName : test.name}
              detail={test.details.find(x => x.startQuestion <= numberName && numberName <= x.endQuestion)}
              doSaveQuestionChanges={(newQuestion, callback) => doSaveQuestionChanges(newQuestion, idx, false, callback)}
              doDeleteQuestion={(callback) => doDeleteQuestion(idx, callback)}
              doMoveQuestionUp={(callback) => doMoveQuestionUp(question, idx, callback)}
              isProcessing={isProcessing}
              setIsProcessing={setIsProcessing}
            />
          )
        })}
      </div>
    </div>
  )
}
