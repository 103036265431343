import React, { useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { FirebaseContext } from '../contexts/firebaseContext'
import { TestContext } from '../contexts/testContext'
import { Test, TestContextInterface } from '../types'
import { TEST_TYPES } from './admin-tests'
import { Button, Input, TextArea } from './common'

const StyledAdminTestGeneral = styled.div`
  .grid{
    display: grid;
    grid-template-columns: 20% 80%;

    .left, >label{
      grid-column-start: 1;
      display: flex;
      align-items: center;
    }

    >textarea{
      font-size: 13px;
      line-height: 1;
    }

    .right, >input, >textarea{
      grid-column-start: 2;
      
      &.flex{
        display: flex;
        align-items: center;
        justify-content: space-around;

        border: 1px solid transparent;
        font-size: 1rem;
        line-height: 1.25;
        padding: .5rem .75rem;
        border-radius: .25rem;
        margin: .25rem;

        &.error{
          border-color: red;
          box-shadow: #ff000073 0px 0px 4px 1px;
        }
      }
    }

    .full-span{
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
  >button{
    width: 100%;
  }
`

interface AdminTestGeneralProps {
  test?: Test
  testType?: string
  goToTest?: (type: string, name: string) => void
  highestTestId?: number
  isProcessing: boolean
  setIsProcessing: Function
}

export const AdminTestGeneral = ({ test, testType, goToTest, highestTestId, isProcessing, setIsProcessing }: AdminTestGeneralProps) => {
  const [type, setType] = useState<string | undefined>(testType ? testType : undefined)
  const [name, setName] = useState<string>(test ? test.name : '')
  const [categoryName, setCategoryName] = useState<string>(test ? test.categoryName : '')
  const [coverPage, setCoverPage] = useState<string>(test ? test.coverPage : '')
  const [references, setReferences] = useState<string>(test ? test.references : '')
  const [description, setDescription] = useState<string>(test ? test.description : '')
  const [summary, setSummary] = useState<string>(test ? test.summary : '')

  const [changesMade, setChangesMade] = useState<boolean>(false)

  const [typeError, setTypeError] = useState<boolean>(false)
  const [nameError, setNameError] = useState<boolean>(false)
  const [catNameError, setCatNameError] = useState<boolean>(false)
  const [isHidden, setIshidden] = useState<boolean>(test ? test.isHidden : false);

  const { firebase } = useContext(FirebaseContext);
  const { loadTestsFlashcards } = useContext<TestContextInterface>(TestContext)

  const mountedRef = useRef(false)

  useEffect(() => {
    if (mountedRef.current) setChangesMade(true)
  }, [type, name, categoryName, coverPage, references, description, summary, isHidden])

  useEffect(() => {
    if (mountedRef.current) {
      setTypeError(false)
      setNameError(false)
      setCatNameError(false)
    }
  }, [type, setTypeError])

  useEffect(() => {
    if (mountedRef.current) setNameError(false)
  }, [name, setNameError])

  useEffect(() => {
    if (mountedRef.current) setCatNameError(false)
  }, [categoryName, setCatNameError])

  useEffect(() => {
    if (!mountedRef.current) mountedRef.current = true
  })

  const saveTest = () => {
    var valid = true
    if (type === undefined) {
      setTypeError(true)
      valid = false
    } else setTypeError(false)

    if (type === TEST_TYPES.FULL && name === '') {
      setNameError(true)
      valid = false
    } else setNameError(false)

    if (type === TEST_TYPES.CAT && categoryName === '') {
      setCatNameError(true)
      valid = false
    } else setCatNameError(false)

    if (valid && type) {
      setIsProcessing(true)
      let newTest: any = {}

      if (test) {
        newTest = { ...test }
      } else if (highestTestId) {
        newTest.id = highestTestId + 1
        newTest.questions = []
        newTest.details = []
      } else {
        throw (new Error('props test and hightestTestId cannout be both undefined'))
      }

      if (type === TEST_TYPES.CAT) {
        newTest.categoryName = categoryName
      } else {
        newTest.categoryName = null
      }

      newTest.name = name
      newTest.coverPage = coverPage !== '' ? coverPage : null
      newTest.references = references !== '' ? references : null
      newTest.description = description !== '' ? description : null
      newTest.summary = summary !== '' ? summary : null
      newTest.isHidden = isHidden

      if (test) {
        console.log('updaing test')
        firebase.doUpdateTest(type, newTest, () => {
          loadTestsFlashcards(() => {
            setChangesMade(false)
            setIsProcessing(false)
          })
        })

      } else if (goToTest) {
        firebase.doAddTest(type, newTest, () => {
          loadTestsFlashcards(() => {
            setChangesMade(false)
            setIsProcessing(false)
            goToTest(type, name)
          })
        })
      } else {
        throw (new Error('props test and goToTest cannot be both undefined'))
      }
    }
  }

  return (
    <StyledAdminTestGeneral className='item-container'>
      {test && <b>ID: {test.id}</b>}
      <div className='grid item'>
        <div className="left">
          Test Type *
        </div>
        <div className={`right flex ${typeError && 'error'}`}>
          <div>
            <input
              disabled={isProcessing}
              type='radio'
              checked={type === TEST_TYPES.CAT}
              name={`new_test_type_cat`}
              id={`new_test_type_cat`}
              value={TEST_TYPES.CAT}
              onChange={() => { setType(TEST_TYPES.CAT) }}
            />
            <label
              htmlFor={`new_test_type_cat`}
            >Category Test</label>
          </div>
          <div>
            <input
              disabled={isProcessing}
              type='radio'
              checked={type === TEST_TYPES.FULL}
              name={`new_test_type_full`}
              id={`new_test_type_full`}
              value={TEST_TYPES.FULL}
              onChange={() => { setType(TEST_TYPES.FULL) }}
            />
            <label
              htmlFor={`new_test_type_full`}
            >Full Exam</label>
          </div>
        </div>
        <label
          htmlFor={`new_test_name`}
        >Name{type === TEST_TYPES.FULL && ' *'}</label>
        <Input
          error={nameError}
          disabled={isProcessing}
          id={`new_test_name`}
          type='string'
          defaultValue={name}
          onChange={setName}
        />

        <label
          htmlFor={`new_test_category_name`}
        >Category Name{type === TEST_TYPES.CAT && ' *'}</label>
        <Input
          error={catNameError}
          disabled={type === TEST_TYPES.FULL || isProcessing}
          id={`new_test_name`}
          type='string'
          defaultValue={categoryName}
          onChange={setCategoryName}
        />

        <label
          htmlFor={`new_test_references`}
        >References</label>
        <Input
          disabled={isProcessing}
          id={`new_test_references`}
          type='string'
          defaultValue={references}
          onChange={setReferences}
        />

        <label
          htmlFor={`new_test_description`}
        >Description</label>
        <Input
          disabled={isProcessing}
          id={`new_test_description`}
          type='string'
          defaultValue={description}
          onChange={setDescription}
        />

        <label
          htmlFor={`new_test_coverPage`}
        >Cover Page</label>
        <TextArea
          disabled={isProcessing}
          defaultValue={coverPage}
          onChange={setCoverPage}
          rows={3}
        />

        <label
          htmlFor={`new_test_summary`}
        >Summary</label>
        <TextArea
          disabled={isProcessing}
          defaultValue={summary}
          onChange={setSummary}
          rows={3}
        />
        <Button className='full-span'
          onClick={() =>
            setIshidden(!isHidden)
          }
          secondary
        >{isHidden ? 'Unhide Test' : 'Hide Test'}</Button>
        <Button className='full-span'
          disabled={isProcessing || !changesMade}
          onClick={saveTest}
          secondary
        >{test ? 'Save Changes' : 'Save New Test'}</Button>

      </div>

    </StyledAdminTestGeneral>
  )
}
