import React from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
import "./App.css";
import { Wrapper } from "./components/styles";

import TestProvider from "./contexts/testContext";
import StoreProvider from "./contexts/storeContext";
import { MainSection } from "./routes/main-section";
import { FooterSection } from "./routes/footer-section";
import styled from "styled-components";
import UserProvider from "./contexts/userContext";
import { ROUTES } from "./constants";
import { largeText, StyledHeaderSection } from "./components/common";
import { NavigationSection } from "./routes/nav-section";
import AccountProvider from "./contexts/accountContext";
import GlobalStyle from "./global-styles";

const AppSection = styled.div`
  width: 100%;
  height: 100%;
`;

const App: React.FC = () => {
  return (
    <Router>
      <GlobalStyle />
      <StyledHeaderSection />
      <Wrapper>
        <TestProvider>
          <AccountProvider>
            <UserProvider>
              <StoreProvider>
                <AppSection>
                  <NavigationSection />
                  <MyErrorBoundary>
                    <MainSection />
                  </MyErrorBoundary>
                  <FooterSection />
                </AppSection>
              </StoreProvider>
            </UserProvider>
          </AccountProvider>
        </TestProvider>
      </Wrapper>
    </Router>
  );
};

export default App;

interface MyErrorBoundaryProps {
  children: React.ReactNode;
}
interface MyErrorBoundryState {
  hasError: boolean;
}

const StyledError = styled.div`
  font-size: ${largeText};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
`;

class MyErrorBoundary extends React.Component<MyErrorBoundaryProps, MyErrorBoundryState> {
  state: MyErrorBoundryState = {
    hasError: false,
  };

  componentDidCatch(error: Error) {
    console.error("Error", error.message);
    this.setState({
      hasError: true,
    });
  }

  render() {
    return (
      <>
        {this.state.hasError && (
          <StyledError>
            <span>Doh! there was an error</span>
            <Link
              to={{
                pathname: ROUTES.HOME,
              }}
              onClick={(e: any) => {
                // change location to DEFAULT_VIEW and update state.
                this.setState({ hasError: false });
              }}
            >
              Go Back Home
            </Link>
          </StyledError>
        )}
        {!this.state.hasError && this.props.children}
      </>
    );
  }
}
