import React, { useContext } from "react";
import { StoreContext } from "../contexts/storeContext";
import { Button, smallText, inverseText, deviceSize, StyledHr } from "./common";
import styled from "styled-components";
import { LoginForm } from "./login-form";
import { LoginPageInfo } from "./login-page-info";
import moment from "moment";

export const StyledWelcome = styled.div`
  align-items: center;
  display: flex;
  margin-right: 25px;

  .welcome-mssg {
    color: ${inverseText};
    font-size: ${smallText};
  }

  > button {
    margin: 0rem 0.5rem;
    width: unset;
  }
  @media ${deviceSize.tablet} {
    align-self: flex-end;
    .welcome-mssg{
      display: none;
    }
  }
`;

export const Welcome = () => {
  const { loggedInUser, logoutUser, userExpiration } = useContext(StoreContext);

  if (!loggedInUser) return null;
  return (
    <StyledWelcome>
      <div>
        <div className='welcome-mssg'>Welcome, {loggedInUser && loggedInUser.user && loggedInUser.user.email}</div>
        <div className='welcome-mssg'>Account expires in: {moment(userExpiration.seconds * 1000).format('MMM D YYYY')}</div>
      </div>
      <Button
        onClick={(e: any) => {
          e.preventDefault();
          logoutUser();
        }}
      >
        Logout
      </Button>
    </StyledWelcome>
  );
};

const StyledLoginContainer = styled.div`

  >div:nth-child(1){
    text-align: center;
    font-size: 28px;
    font-weight: 400;
    font-style: normal;
    text-transform: none;
    letter-spacing: 0px;
    line-height: 1.4em;
    color: #222;
    margin-bottom: 1em;
  }

  >div:nth-child(2){
    text-align: center;
    font-size: 17px;
    // font-weight: 300;
    font-style: normal;
    text-transform: none;
    letter-spacing: 0px;
    line-height: 1.15em;
    margin-bottom: 1em;
  }

  >div:nth-child(3){
    display:flex;
    flex-direction: row;

    @media ${deviceSize.tablet} {
      flex-direction: column;
    }

    >div{
      padding: 17px;
      max-width: 100%;

    }
  }

  hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0,0,0,.1);
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }

  > div:nth-of-type(4){
    text-align: center;
    font-size: 28px;
    line-height: 1.4em;
    color: #222;
    margin: 2em 0 1em 0;
  }

  > div:nth-of-type(5){
    text-align: center;
  }
  > div:nth-of-type(6){
    display:flex;
    justify-content: center;
    margin-top: 17px;
    > a {
      > button{
        margin: 0 auto;
        color: #3d548a;
        border-radius: 3px;
        border-width: 2px;
        border-style: solid;
        background-color: transparent;
        transition: 0.1s background-color linear, 0.1s color linear;
        border-color: #3d548a;
        letter-spacing: .08em;
        text-transform: uppercase;
        font-size: 15px;
        font-weight: 500;
        padding: 21px 34px;
        cursor: pointer;
  
        &:hover{
          background-color: #3d548a;
          color: #fff;
        }
      }
    }
  }
`

export function Login() {
  const { loggedInUser } = useContext(StoreContext);

  if (loggedInUser) return null;
  return (
    <StyledLoginContainer>
      <div>
        Complete study programs for the US Customs Broker Exam
      </div>
      <div>
        Designed by an experienced U.S. Customs Broker, refined by over
        20 years of student feedback
      </div>
      <div>
        <LoginForm />
        <LoginPageInfo />
      </div>
      <StyledHr />
      <div>Questions?</div>
      <div>We're on standby, happy to help. Call us at 512-441-9795</div>
      <div><a href={'https://www.cbprepcourse.com/contact'}><button>Get in touch</button></a></div>
    </StyledLoginContainer>
  );
}
