
import React, { useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components';
import { FirebaseContext } from '../contexts/firebaseContext';
import { TestContext } from '../contexts/testContext';
import { Flashcard, TestContextInterface } from '../types';
import { Button, TextArea, SelectDropdown } from './common';

const StyledAdminFlashcard = styled.div`
  textarea{
    font-size: 13px;
    line-height: 1;
    margin: 0px;
    margin-left: .2rem;
    margin-bottom: .1rem;
  }
`

interface AdminFlashcardItemProps {
  flashcard?: Flashcard
  highestId?: number
  t: string
  groupedFlashcards: { groupName: string, flashcards: Flashcard[] }[]
}

export const AdminFlashcardItem = ({ flashcard, highestId, t, groupedFlashcards }: AdminFlashcardItemProps) => {
  const { firebase } = useContext(FirebaseContext);
  const { loadTestsFlashcards } = useContext<TestContextInterface>(TestContext)
  const [a, setA] = useState<string>(flashcard ? flashcard.a : '')
  const [q, setQ] = useState<string>(flashcard ? flashcard.q : '')
  const [newT, setNewT] = useState<string>(flashcard ? flashcard.t : '')
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  const [changesMade, setChangesMade] = useState<boolean>(false)
  const mounted = useRef<boolean>(false)

  useEffect(() => {
    if (mounted.current) setChangesMade(true)
  }, [a, q, newT])

  useEffect(() => {
    if (!mounted.current) mounted.current = true
  })

  const saveChanges = () => {
    setIsProcessing(true)
    if (flashcard) {
      const flashcardData = { ...flashcard, a, q, t: newT}
      firebase.doUpdateFlashcard(flashcardData, () => {
        loadTestsFlashcards(() => {
          setIsProcessing(false)
          setChangesMade(false)
        })
      })
    } else if (highestId !== undefined && t !== undefined) {
      const flashcardData = { id: highestId + 1, a, q, t }
      firebase.doAddFlashcard(flashcardData, () => {
        loadTestsFlashcards(() => {
          setIsProcessing(false)
          setChangesMade(false)
        })
      })
    } else {
      throw (new Error('if flashcard is not defined, highestId and t must be defined'))
    }
  }

  const deleteFlashcard = () => {
    if (flashcard) {
      setIsProcessing(true)
      firebase.doDeleteFlashcard(flashcard.id, () => {
        loadTestsFlashcards(() => { })
      })
    }
  }

  return (
    <StyledAdminFlashcard className='item'>
      <div className="header">
        {flashcard ?
          <div>
            <Button
              red
              disabled={isProcessing}
              onClick={deleteFlashcard}
            >Delete</Button>
            ID: {flashcard.id}
          </div>
          :
          <div>
            New Flashcard
          </div>
        }
        <Button
          secondary
          disabled={isProcessing || !changesMade}
          onClick={saveChanges}
        >Save Changes</Button>
      </div>
      <div>
        <TextArea
          disabled={isProcessing}
          defaultValue={q}
          onChange={setQ}
        />
        <TextArea
          disabled={isProcessing}
          defaultValue={a}
          onChange={setA}
        />
        <SelectDropdown
          defaultValue={newT}
          onChange={setNewT}
          options={groupedFlashcards.map(x => x.groupName)}
        />
      </div>
    </StyledAdminFlashcard>
  )
}
