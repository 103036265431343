import React from 'react'
import styled from 'styled-components'

const StyledTestInfo = styled.div`
`

export const TestInfo = () => {
  return (
    <StyledTestInfo>
      <b>
       Announcements and stuff you should know
      </b>
       <p>        
        <b>11.13.24
      </b>  
      </p>
      <p>CBP posted the exam and official answer key.  I will review the test and try
        to find questions to appeal.  If you are within 4 questions, please review your
        incorrect answers and see if there is an issue to appeal.  Contact m as needed.
        Also, I will make any necessary book updtes and post those as soon as possible.
        </p>
         <p>
         <b>Space reserved for future great stuff
      </b>  
      </p>
      <p>
      <b>
       </b>  
      </p>
      <p>
      </p>
      <p>
      </p>
    </StyledTestInfo>
  )
}
