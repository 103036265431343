import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { PASSABLE_LIMIT } from "../constants";
// Nebulas Blue
export const primarytextColor = "#ffffff"; // all white
export const topBarHeaderColor = "#3e5489";
export const almostWhite = "#f3f6fb"; // almost white
export const hoverColor = "#3e5489"; // next lightest
const text2Color = "#afc3e1";
export const altHoverColor = "#86a5d2";
export const buttonColor = topBarHeaderColor; // light blue
export const buttonColorDarker = "#203556"
export const mainColor = "#3f69aa";
export const takeTestDarkBlue = '#016BAC';
//export const altHoverColor = "#39609a";
//const border2Color = "#335589";
//const border3Color = "#2c4b78";
//const border4Color = "#264067";
export const borderColor = "#203556"; // dark blue
export const inverseText = "#000000";
export const highlight = "#f0e277"; // highlight from Meadowlark
//const highlightDarket = "#e6ce1b"; // darker highlight from Meadowlark
export const fadedColor = "#b9b9b9";

export const tinyText = "0.25rem"
export const smallText = "0.75rem";
export const regText = "1rem";
export const largeText = "1.5rem";
export const veryLargeText = "3.5rem";
const red = '#b82a17'

export const regBorderRadius = '10px'

export const screenSize = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

export const deviceSize = {
  mobileS: `(max-width: ${screenSize.mobileS})`,
  mobileM: `(max-width: ${screenSize.mobileM})`,
  mobileL: `(max-width: ${screenSize.mobileL})`,
  tablet: `(min-width: ${screenSize.mobileS}) and (max-width: ${screenSize.tablet})`,
  laptop: `(min-width: ${screenSize.tablet}) and (max-width: ${screenSize.laptop})`,
  laptopL: `(min-width: ${screenSize.tablet}) and (max-width: ${screenSize.laptopL})`,
  desktop: `(min-width: ${screenSize.laptop}) and (max-width: ${screenSize.desktop})`,
};

export const PROGRESS_STATUS = {
  SUCCESS: "#bed39b",
  FAILURES: "#f29189",
  ITEMSLEFT: text2Color,
};

export const backgroundColor = mainColor;
export const infoBackgroundColor = 'rgba(90,90,90,.03)'
export const secondaryBackgroundColor = '#d7e1f0';

const StyledTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0;

  > span:first-of-type {
    font-size: 45px;
    font-weight: 500;
    text-align: center;

    @media ${deviceSize.tablet} {
      font-size: ${largeText};
    }
  }
  > span:last-of-type {
    font-size: 15px;
    font-weight: 400;
    line-height: 1.6em;
    color: ${fadedColor};

    @media ${deviceSize.mobileL} {
      font-size: ${smallText};
    }
  }

  @media ${deviceSize.tablet} {
      margin: 10px 40px;
      height: 38px;
  }

`;

export const AppTitle = ({
  title,
  description,
}: {
  title?: string
  description?: string
}) => {
  if (!title) title = `The Customs Broker Exam Prep Course`
  if (!description && description !== '') description = `Your Key to Passing the U.S. Customs Broker Exam`
  return <StyledTitle>
    <span>{title}</span>
    <span>{description}</span>
  </StyledTitle>
}

interface ButtonProps {
  secondary?: boolean;
  selected?: boolean;
  disabled?: boolean;
  noHover?: boolean;
  red?: boolean
}

export const StyledHeaderSection = styled.div`
  background: ${topBarHeaderColor};
  min-height: 8px;
  width: 100%;
`;

const slideMenuDelay = .5
export const StyledNavigationSection = styled.div`
  .hamburger-icon{
    display: none;
  }
  .nav-menu{
    display: flex;
    flex-direction: column;
    .nav-list{
      display: flex;
      justify-content: space-around;
      width: 100%;
    }
  }

  .logout{
    display: flex;
    flex-direction: row-reverse;
  }

  .dim-screen{
    display: none;
    
  }

  @media ${deviceSize.tablet} {
    flex-wrap: wrap;
    .hamburger-icon{
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      cursor: pointer;
      width: 28px;
      height: 28px;
      margin: 20px 10px;
      >div{
        width: 100%;
        height: 6px;
        border-radius: 3px;
        background-color: ${topBarHeaderColor};
      }
    }
    .dim-screen{
      position:fixed;
      display: initial;
      width: 100vw;
      height: 100vh;
      top:0;
      background-color: #48484880;
      z-index: 1;
      pointer-events: all;
      transition: background-color ${slideMenuDelay}s ease-in-out;
      &.hide{
        background-color: #ffffff00;
        pointer-events: none;
      }
    }
    .nav-menu{
      position: fixed;
      top: 0px;
      left:0;
      min-width: 200px;
      max-width: 200px;
      height: calc(100vh - 52px);
      border-top: 8px ${topBarHeaderColor} solid;
      background-color: white;
      justify-content: space-between;
      padding: 35px;
      z-index: 2;
      transition: left ${slideMenuDelay}s ease-in-out;

      .hamburger-icon{
        position: absolute;
        top: 0;
        left: 0;
      }

      .nav-list{
        margin-top: 2rem;
        flex-direction: column;
        justify-content: space-around;
        a{
          font-size: 1rem !important;
        }
      }

      .logout{
        margin-bottom: 15px;
        display: flex;
        flex-direction: initial;
        button{
          width: 100%
        }
      }

      &.hide{
        left: -270px;
      }
    }
  }
`;

export const Button = styled.button`
  color: ${(props: ButtonProps) =>
    props.disabled ? 'grey' :
      props.secondary ? almostWhite :
        props.red ? almostWhite : buttonColor
  };
  background-color: ${(props: ButtonProps) =>
    props.disabled ? secondaryBackgroundColor :
      props.secondary ? buttonColor :
        props.red ? red : 'transparent'
  };

  border: 1px solid ${(props: ButtonProps) =>
    props.disabled ? secondaryBackgroundColor :
      props.red ? 'transparent' : buttonColor
  };

  box-shadow: ${(props: ButtonProps) =>
    props.selected ? `0 0 0 3px ${buttonColor}80` : 'none'
  };

  white-space: nowrap;
  padding: .5rem .75rem;
  font-size: ${regText};
  line-height: 1.25;
  border-radius: .25rem;
  transition: all .15s ease-in-out;
  background-image: none;
  margin: .25rem;

  cursor: ${(props: ButtonProps) =>
    props.disabled ? 'default' : 'pointer'
  };
  
  &:hover {
    color: ${(props: ButtonProps) =>
    props.disabled ? 'grey' :
      props.red ? 'red' : almostWhite
  };
    background-color: ${(props: ButtonProps) =>
    props.disabled ? secondaryBackgroundColor :
      props.secondary ? buttonColorDarker :
        props.red ? 'transparent' : buttonColor
  };
    border-color: ${(props: ButtonProps) =>
    props.disabled ? secondaryBackgroundColor :
      props.secondary ? buttonColorDarker :
        props.red ? red : buttonColor
  };
  }

  &:focus {
    outline: none;
  }
`

export const RoundButton = styled.button`
  background: ${primarytextColor};
  border-radius: 10px;
  color: ${inverseText};
  border: 1px solid ${borderColor};
  width: 50px;
  outline: none;
  cursor: pointer;

  ${(props: ButtonProps) =>
    props.selected &&
    css`
      background: ${highlight};
    `};

  @media ${deviceSize.desktop} {
    &:hover {
      background: ${highlight};
    }
  }
`;

export const SquareButton = styled(RoundButton)`
   background: ${mainColor};
   color: ${primarytextColor};
   padding: 0.5rem;
   white-space: nowrap;
   width: 7rem;
   align-self: center;
   border-radius: 5px;

   &:hover {
      background: ${altHoverColor};
    }
    &:disabled {
      background: ${almostWhite};
      color: ${fadedColor};
    }
`
const StyledNavLink = styled.span`
  padding: 0.5rem;
  text-transform: uppercase;
  font-size: ${smallText};
  border-bottom: 4px solid white;
  transition: all .25s ease-in-out;

  &:hover{
    border-bottom: 4px solid ${altHoverColor};
  }
  
  > a {
    color: ${inverseText};
    font-size: ${smallText};
    font-weight: 400;
    text-transform: uppercase;

    &:link {
      text-decoration: none;
    }
  }

  ${(props: NavLinkStyleProps) =>
    props.active &&
    css`
      border-bottom: 4px solid ${hoverColor} !important;
    `};
`;

interface NavLinkStyleProps {
  active?: boolean;
}
interface NavLinkProps {
  page: string;
  text: string;
  active?: boolean;
  disabled?: boolean;
  hideMenu: () => void;
}

export const AppNavLink = (props: NavLinkProps) => (
  <StyledNavLink active={props.active} onClick={props.hideMenu}>
    {!props.disabled ? (
      <NavLink to={props.page}>
        {props.text}
      </NavLink>
    ) : (
      props.text
    )}
  </StyledNavLink>
);

interface InputProps {
  type: string
  onChange: (value: string) => void;
  defaultValue?: string | null
  id?: string
  disabled?: boolean
  error?: boolean
}

const StyledInput = styled.input`
  white-space: nowrap;
  border: 1px solid transparent;
  padding: .5rem .75rem;
  font-size: ${regText};
  line-height: 1.25;
  border-radius: .25rem;
  -webkit-transition: all .15s ease-in-out;
  transition: all .15s ease-in-out;
  background-color: transparent;
  background-image: none;
  border-color: ${buttonColor};
  margin: .25rem;

  ${(props: InputProps) => props.error && css`
    border-color: red;
    box-shadow: #ff000073 0px 0px 4px 1px;
  `}

  &:disabled{
    border-color: ${fadedColor};
  }

  &:focus{
    outline:none;
  }
`

export const Input = ({ type, onChange, defaultValue, id, disabled, error }: InputProps) => {
  return (
    <StyledInput
      error={error}
      disabled={disabled}
      id={id}
      type={type}
      onChange={(e: React.FormEvent<HTMLInputElement>) => onChange(e.currentTarget.value)}
      defaultValue={defaultValue}
    />
  )
}

interface TextAreaProps {
  defaultValue: string
  onChange: (value: string) => void
  rows?: number
  disabled?: boolean
  error?: boolean
}

const StyledTextArea = styled.textarea`
  border: 1px solid transparent;
  padding: .5rem .75rem;
  font-size: ${regText};
  line-height: 1.25;
  border-radius: .25rem;
  transition: all .15s ease-in-out, height none;
  background-color: transparent;
  background-image: none;
  border-color: ${buttonColor};
  margin: .25rem;
  max-width: calc(100% - 2rem);
  min-width: calc(100% - 2rem);

  ${(props: InputProps) => props.error && css`
    border-color: red;
    box-shadow: #ff000073 0px 0px 4px 1px;
  `}

  &:disabled{
    border-color: ${fadedColor};
  }

  &:focus{
    outline:none;
  }
`

export const TextArea = ({ rows = 2, error, disabled, onChange, defaultValue }: TextAreaProps) => {
  return <StyledTextArea
    defaultValue={defaultValue}
    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => onChange(e.currentTarget.value)}
    rows={rows}
    disabled={disabled}
    error={error}
  />
}

interface SelectProps {
  defaultValue: string;
  onChange: Function;
  options: string[];
  optionTexts?: string[]
  firstDisabled?: boolean
  disabled?: boolean
}

const StyledSelect = styled.select`
  white-space: nowrap;
  border: 1px solid transparent;
  padding: .5rem .75rem;
  font-size: ${regText};
  line-height: 1.25;
  border-radius: .25rem;
  -webkit-transition: all .15s ease-in-out;
  transition: all .15s ease-in-out;
  color: ${buttonColor};
  background-color: transparent;
  background-image: none;
  border-color: ${buttonColor};
  margin: .25rem;

  &:focus{
    outline:none;
  }

  &:disabled{
    color: ${fadedColor};
    border-color: ${fadedColor};
  }
`;

export const SelectDropdown = ({ defaultValue, options, onChange, optionTexts, firstDisabled, disabled }: SelectProps) => {
  const [selected, setSelected] = useState<string>(defaultValue);

  useEffect(() => {
    selected !== defaultValue && setSelected(defaultValue)
  }, [defaultValue, selected])

  return (
    <StyledSelect
      disabled={disabled}
      onChange={(e: any) => {
        const selected = e.target.value;
        setSelected(selected);
        onChange(selected);
      }}
      value={selected}
    >
      {options.map((option, i) => (
        <option disabled={firstDisabled && i === 0} key={`${i}_${option}`} value={option}>
          {optionTexts && optionTexts[i] ? optionTexts[i] : option}
        </option>
      ))}
    </StyledSelect>
  );
};

export interface ProgressProps {
  pct: number;
  extraPadding?: boolean;
  smallFont?: boolean;
}

export const StyledProgress = styled.span`
  ${(props: ProgressProps) =>
    css`
      padding: 0.25rem;
      display: inline-block;
      border-radius: 2px;
      background: ${props.pct >= PASSABLE_LIMIT ? PROGRESS_STATUS.SUCCESS : PROGRESS_STATUS.FAILURES};
      border: 1px solid ${borderColor};
      color: ${inverseText};
      font-size: ${props.smallFont ? smallText : regText};
      text-align: center;
    `};
`;

interface StyledAppErrorProps {
  isInfo: boolean;
}


export const StyledAppMessage = styled.div<StyledAppErrorProps>`
   display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background: ${({ isInfo }: any) => isInfo ? PROGRESS_STATUS.SUCCESS : PROGRESS_STATUS.FAILURES};
  color: ${inverseText};
  font-size: ${regText};

  > span {
    flex-grow: 1;
    text-align: center;
  }

  > button {
    background: transparent;
    border: none;
    color: ${inverseText};
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0;
    margin-left: 10px;
  }
`;

export const StyledHr = styled.hr`
  width: 100%;
  background: transparent;
  border: none;
  border-top: 1px solid rgba(51,51,51,.08);
  margin: 1rem 0;
`
export const ContentBox = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${infoBackgroundColor};
  > div:nth-child(1){
    width: 90%;
    max-width: 1400px;
    padding: 20px;

    @media ${deviceSize.tablet} {
      width: 100%;
    }
    
    @media (min-width: 576px) {
      max-width: 540px;
    }

    @media (min-width: 768px) {
        max-width: 720px;
    }

    @media (min-width: 992px) {
        max-width: 960px;
    }

    @media (min-width: 1200px) {
        max-width: 1140px;
    }
  }
`