import React from "react"
import styled from "styled-components"
import { ANSWER_LETTERS } from "../constants"
import { Question, Test, UserQuestionAnswer, ShuffledAnswers } from "../types"
import { SquareButton, infoBackgroundColor, RoundButton } from "./common"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StyledAnswerLine = styled.div`
  display: flex;
  align-items: flex-start;  // Add this line
  padding: 0.2rem;
  border-radius: 15px;
  
  &.correct {
    background-color: #85ff85;
    border: 2px solid #4caf50;
  }
  
  &.wrong {
    background-color: #ff7a7a;
    border: 2px solid #f44336;
  }
  
  > button, > div {
    width: 30px;  // Set a fixed width for the container
    height: 20px;  // Set a fixed height for the container
    min-width: 20px;  // Ensure minimum width
    max-width: 30px;  // Ensure maximum width
    margin-right: 1rem;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  > span {
    padding-left: 0;  // Update this line
  }

  @media print {
    &.correct {
      background-color: #85ff85 !important;
      -webkit-print-color-adjust: exact;
      print-color-adjust: exact;
    }
    
    &.wrong {
      background-color: #ff7a7a !important;
      -webkit-print-color-adjust: exact;
      print-color-adjust: exact;
    }
  }
`;

const StyledShowAnswerContainer = styled.div`
  margin-top: 1rem;
  border-radius: 15px;
  padding: 1rem;
  background-color: ${infoBackgroundColor};
  text-align: left;

  > div {
    margin-bottom: 1rem;
  }

  b {
    display: block;
    margin-bottom: 0.5rem;
    font-size: 1.1em;
  }
`;

const StyledTakeTestQuestion = styled.div<{ isFullPage?: boolean }>`
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: ${(props: { isFullPage?: boolean }) => props.isFullPage ? '0' : '124px'};

  .question-img-container{
    margin-right: .5rem;
    display: inline-block;
    
    img{
      width: 100%;
      max-width: 100%;
      height: auto; 
    }
  }

  >div{
    flex: 1;
    background-color: white;
    padding: ${(props: { isFullPage?: boolean }) => props.isFullPage ? '0 1rem' : '1rem'};
    max-width: ${(props: { isFullPage?: boolean }) => props.isFullPage ? '100%' : 'calc(100% - 18rem)'};
    
    &.question-text-container{
      div{

        &.question-general-info{
          margin-bottom: ${(props: { isFullPage?: boolean }) => props.isFullPage ? '0' : '.5rem'};
        }
      }
    }
  }
`

const AnswerCircle = styled.div`
  width: 5px;
  height: 5px;
  min-width: 5px;
  min-height: 5px;
  max-width: 5px;
  max-height: 5px;
  border-radius: 50%;
  border: 1px solid black;
`;

interface TakeTestQuestionProps {
  test: Test
  currentQuestion: Question
  currentUserTestAnswerObject: UserQuestionAnswer
  shuffledAnswers: ShuffledAnswers
  answerQuestion: Function
  index: number
  showAnswer: boolean
  updateIndex: Function
  maxQuestions: number
  isFullPage?: boolean; // New prop to determine if it's a full page view
}

export const QuestionHeader = ({ q, t, m }: { q: Question, t: Test, m: number }) => {
  const isFullExam = !t.categoryName
  const testName = t.categoryName ? q.fromTest : '';
  const categoryInfo = q.cbpCategoryName ? `${q.cbpCategory} ${q.cbpCategoryName} - ` : '';


  if (isFullExam) {
    return (
      <StyledTitle>
        <div>{categoryInfo}Question {q.name} of {m}</div>
      </StyledTitle>
    )
  }
  return (
    <StyledTitle>
      <div>{testName} {categoryInfo ? ` - ${categoryInfo}` : ' - '} Question {q.name}</div>
    </StyledTitle>
  )
}

const renderHTML = (htmlContent: string) => {
  return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};


export const getDetails = (test: Test, currentQuestion: Question) => {
  const questionId = parseInt(currentQuestion.name)
  const testId = test.categoryName ? currentQuestion.testId : null;
  let img: any
  for (let i = 0; i < test.details.length; i++) {
    const detail = test.details[i];
    if (questionId >= detail.startQuestion && questionId <= detail.endQuestion && (detail.testId === testId || !testId)) {
      img = { title: detail.title, file: require(`../assets/data/tests/img/${detail.details}`) }
      break
    }
  }
  return img
}

// Move this outside and to the top of your file
const StyledTitle = styled.div`
  font-weight: bold;
  font-size: 1.25rem;
  margin-bottom: 2rem;
`;

export const TakeTestQuestion = (props: TakeTestQuestionProps) => {
  const { 
    currentQuestion, 
    shuffledAnswers, 
    currentUserTestAnswerObject, 
    answerQuestion, 
    index, 
    showAnswer, 
    test, 
    maxQuestions,
    isFullPage = false // New prop to determine if it's a full page view
  } = props;

  const img = getDetails(test, currentQuestion)
  const lastQuestion = index === maxQuestions - 1;

  // Ensure we always have answers to render
  const answersToRender = shuffledAnswers[currentQuestion.id] || currentQuestion.answers || [];

  const renderQuestion = () => (
    <>
      <div className="question-text-container">
        <div className='question-general-info'>
          <QuestionHeader q={currentQuestion} t={test} m={maxQuestions} />
          {img && <div>{renderHTML(img.title)}</div>}
          {img &&
            <div className='question-img-container'>
              <div><img alt={`img_${currentQuestion.id}`} src={img.file} /></div>
            </div>
          }
          {currentQuestion.description && <div>{renderHTML(currentQuestion.description)}</div>}
        </div>
        <div className='question-answers'>
          {answersToRender.map((a, i) => (
            <StyledAnswerLine
              key={a.name}
              className={`
                ${showAnswer && currentQuestion.answer === a.name ? 'correct' : ''}
                ${showAnswer && currentUserTestAnswerObject.answer !== currentQuestion.answer && currentUserTestAnswerObject.answer === a.name ? 'wrong' : ''}
              `.trim()}
            >
              {isFullPage ? (
                 a.description ? (
                  <div style={{ width: '30px', display: 'flex', justifyContent: 'center' }}>
                    <AnswerCircle />
                  </div>
                ) : null
              ) : (
                <RoundButton
                  selected={currentUserTestAnswerObject.answer === a.name}
                  onClick={() => !isFullPage && answerQuestion(index, a.name)}
                  disabled={isFullPage}
                >
                  {ANSWER_LETTERS[i]}
                </RoundButton>
              )}
              <span>{renderHTML(a.description)}</span>
            </StyledAnswerLine>
          ))}
        </div>
        {showAnswer &&
          <StyledShowAnswerContainer>
            {currentQuestion.reason && currentQuestion.reason !== '' &&
              <div>
                <b>Reason:</b>
                <div>{renderHTML(currentQuestion.reason)}</div>
              </div>
            }
            {currentQuestion.citation && currentQuestion.citation !== '' &&
              <div>
                <b>Citation:</b>
                <div>{renderHTML(currentQuestion.citation)}</div>
              </div>
            }
          </StyledShowAnswerContainer>
        }
      </div>
      {!isFullPage && !lastQuestion &&
        <SquareButton 
          disabled={lastQuestion}
          onClick={() => props.updateIndex(index + 1)}
        >
          {"Next Page"}{' '}
          <FontAwesomeIcon size="sm" icon={faChevronRight} />
          <FontAwesomeIcon size="sm" icon={faChevronRight} />
        </SquareButton>
      }
      {!isFullPage && lastQuestion && 
        <StyledShowAnswerContainer>
         Congratulations, end of the Test, Review your Answers
        </StyledShowAnswerContainer>
      }
    </>
  );

  return (
    <StyledTakeTestQuestion isFullPage={isFullPage}>
      {renderQuestion()}
    </StyledTakeTestQuestion>
  );
}