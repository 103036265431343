import React, { useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Answer, Detail, Question, StoreConextInterface, Test } from '../types'
import { Button, Input, SelectDropdown, TextArea } from './common'
import { TEST_TYPES } from './admin-tests'
import { StoreContext } from '../contexts/storeContext'
import { FirebaseContext } from '../contexts/firebaseContext'
import { TestContext } from '../contexts/testContext';
import { TestContextInterface } from '../types';

interface AdminTestQuestionsItemProps {
  questionIndex?: number
  question?: Question
  testType: string
  testName: string
  detail?: Detail
  doSaveQuestionChanges: (newQuestionData: Question, callback?: Function) => void
  doDeleteQuestion?: (callback: Function) => void
  doMoveQuestionUp?: (callback?: Function) => void
  isProcessing: boolean
  setIsProcessing: Function
}

const StyledQuestionContainer = styled.div`
  .header{
    flex-wrap: wrap;

    .title{
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 16px;

      >button{
        margin-right: 0.25rem;
      }

      .hidebox{
        font-weight: normal;
        font-size: 14px;
      }
    }
  }

  input[type="text"]{
    width: fill-available;
  }

  button, select, textarea, input[type="text"]{
    padding: 2px 2px;
    font-size: 13px;
    line-height: 1;
    margin: 0px;
    margin-left: .2rem;
    margin-bottom: .1rem;
  }  

  button{
    padding: 7px;
  }

  .two-columns{
    font-size: 13px;
    display: flex;
    >div{
      display: flex;
      align-items: center;
    }
    span{
      text-align: center;
      width: 135px;
    }
    .long{
      width: fill-available;
    }
    .half{
      width: 50%;
    }
    @media (max-width: 768px) {
      flex-direction: column;
      >div.half{
        width: fill-available;
      }
    }
  }
`

const InputWithCheckbox = styled.div`
    display: flex;
    align-items: center;
  `

const getEmptyAnswers = () => {
  return [
    { name: "A", description: "" },
    { name: "B", description: "" },
    { name: "C", description: "" },
    { name: "D", description: "" },
    { name: "E", description: "" }
  ] as Answer[]
}

export const AdminTestQuestionsItem = (
  { questionIndex, question, testType, testName, detail, doSaveQuestionChanges, doDeleteQuestion, doMoveQuestionUp, isProcessing, setIsProcessing }: AdminTestQuestionsItemProps
) => {
  const {
    testsFlashcards: { tests: { chapter } },
  } = useContext<StoreConextInterface>(StoreContext)
  const { firebase } = useContext(FirebaseContext);
  const { loadTestsFlashcards } = useContext<TestContextInterface>(TestContext)

  const [descriptionData, setDescriptionData] = useState<string>(question ? question.description : '')
  const [reasonData, setReasonData] = useState<string>(question ? question.reason : '')
  const [answerData, setAnswerData] = useState<string>(question ? question.answer : 'A')
  const [citationData, setCitationData] = useState<string>(question ? question.citation : '')
  const [answersData, setAnswersData] = useState<Answer[]>(question ? question.answers : getEmptyAnswers())
  const [cbpCategoryData, setCbpCategoryData] = useState<string | null>(question ? question.cbpCategory : '')
  const [cbpCategoryNameData, setCbpCategoryNameData] = useState<string | null>(question ? question.cbpCategoryName : '')
  const [categoryTestToSendQuestionTo, setCategoryTestToSendQuestionTo] = useState<string>('')
  const [hideData, setHideData] = useState<boolean>(question ? (question.hide !== undefined ? question.hide : false) : false)
  
  const [sentToCatTest, setSentToCatTest] = useState<boolean>(false)
  const [changesMade, setChangesMade] = useState<boolean>(false)

  const [descriptionError, setDescriptionError] = useState<boolean>(false)
  const [answerDataError, setAnswerDataError] = useState<boolean>(false)

  const mountedRef = useRef(false)

  useEffect(() => { if (mountedRef.current) setChangesMade(true) },
    [descriptionData, reasonData, answerData, citationData, answersData, hideData]
  )

  useEffect(() => {
    setDescriptionError(false)
  }, [descriptionData])

  useEffect(() => {
    setAnswerDataError(false)
  }, [answerData, answersData])

  useEffect(() => { mountedRef.current = true }, [])

  const saveQuestionChanges = () => {
    let valid = true

    if (descriptionData === '') {
      setDescriptionError(true)
      valid = false
    }
    const selectedAnswer = answersData.find(x => x.name === answerData)
    if (!selectedAnswer || selectedAnswer.description === '') {
      setAnswerDataError(true)
      valid = false
    }

    if (valid) {
      setIsProcessing(true)
      let questionData = {} as Question;
      if (question) {
        questionData = {
          ...question,
          hide: hideData,
          description: descriptionData,
          reason: reasonData,
          answer: answerData,
          citation: citationData,
          answers: answersData,
          cbpCategory: cbpCategoryData,
          cbpCategoryName: cbpCategoryNameData,
        }
      } else {
        questionData = {
          fromTest: '',
          hide: hideData,
          description: descriptionData,
          reason: reasonData,
          answer: answerData,
          citation: citationData,
          answers: answersData,
          cbpCategory: cbpCategoryData,
          cbpCategoryName: cbpCategoryNameData,
        } as Question
      }

      let callback = () => {
        setIsProcessing(false)
      }
      
      if (question) callback = () => {
        setIsProcessing(false)
        setChangesMade(false)
      }
      doSaveQuestionChanges(questionData, callback)
    }
  }

  const updateAnswerDescription = (newDescription: string, index: number) => {
    let items = [...answersData]
    let item = { ...items[index] }
    item.description = newDescription
    items[index] = item;
    setAnswersData(items)
  }

  const addFullExamQuestionToCategoryTest = () => {
    if (question) {
      setIsProcessing(true)
      var categoryTest = {
        ...chapter.find(x => x.categoryName === categoryTestToSendQuestionTo),
      } as Test
      if (categoryTest) {
        var modifiedQuestion = { ...question, fromTest: testName } as Question

        if (detail) {
          const exisitingDetail = categoryTest.details.find(x => 
            x.testId === detail.testId
            && x.details === detail.details
            && x.startQuestion === detail.startQuestion
            && x.endQuestion === detail.endQuestion
            && x.title === detail.title
          )

          if(!exisitingDetail){
            categoryTest.details = [...categoryTest.details, {
              ...detail
            }]
          }
        }

        categoryTest.questions = [modifiedQuestion, ...categoryTest.questions]

        firebase.doUpdateTest(TEST_TYPES.CAT, categoryTest, () => {
          loadTestsFlashcards(() => {
            setSentToCatTest(true)
            setCategoryTestToSendQuestionTo('')
            setIsProcessing(false)
          })
        })
      }
    }
  }

  const moveQuestionUp = () => {
    if (question && doMoveQuestionUp) {
      doMoveQuestionUp(() => {
        setIsProcessing(false);
      })
    }
  }
  const deleteQuestion = () => {
    if (question && doDeleteQuestion) {
      setIsProcessing(true)
      doDeleteQuestion(() => {
        setIsProcessing(false)
      })
    }
  }

  return (
    <StyledQuestionContainer className='item'>
      <div className='header'>
        {question ?
          <div className='title'>
            <Button
              red
              onClick={deleteQuestion}
              disabled={isProcessing}
            >Delete</Button> Quesiton {question.name}
            {
              (testType === TEST_TYPES.CAT && question.fromTest !== null) &&
              ` from: ${question.fromTest}`
            }
            <div className="hidebox">
              <input
                type="checkbox"
                name={`question_${question.id}_hide`}
                id={`question_${question.id}_hide`}
                checked={hideData}
                disabled={isProcessing}
                onChange={() => setHideData(!hideData)}
              />
              <label
                htmlFor={`question_${question.id}_hide`}
              >{!hideData ? 'hide' : 'hidden'}</label>
            </div>
            <Button disabled={questionIndex === 0} onClick={moveQuestionUp}>Move to Top</Button>
          </div>
          :
          <div className='title'>New Quesiton</div>
        }
        {(question && testType === TEST_TYPES.FULL) &&
          <div>
            <SelectDropdown
              defaultValue={categoryTestToSendQuestionTo}
              options={['', ...chapter.map(x => x.categoryName)]}
              optionTexts={
                ['Select Test', ...chapter.map(x => x.categoryName)
                ]}
              onChange={(value: string) => {
                setCategoryTestToSendQuestionTo(value)
              }}
            />
            <Button
              success={sentToCatTest}
              disabled={categoryTestToSendQuestionTo === '' || isProcessing}
              onClick={() => { addFullExamQuestionToCategoryTest() }}
            >{!sentToCatTest ? 'Send To Category Test' : 'Sent!'}</Button>
          </div>
        }
        <Button
          secondary
          disabled={isProcessing || !changesMade}
          onClick={saveQuestionChanges}
        >Save Changes</Button>

      </div>
      <TextArea
        error={descriptionError}
        disabled={isProcessing}
        rows={3}
        onChange={(value) => setDescriptionData(value)}
        defaultValue={descriptionData}
      />
      {answersData.map((answer, idx) => (
        <InputWithCheckbox key={`ans_${question ? question.id : 'new'}_${answer.name}`}>
          <input
            disabled={isProcessing}
            type='radio'
            checked={answer.name === answerData}
            name={`ans_${question ? question.id : 'new'}`}
            id={`ans_${question ? question.id : 'new'}_${answer.name}`}
            value={answer.name}
            onChange={(e) => { setAnswerData(e.currentTarget.value) }}
          />
          <label
            htmlFor={`ans_${question ? question.id : 'new'}_${answer.name}`}
          >{answer.name}</label>
          <Input
            error={answer.name === answerData && answerDataError}
            disabled={isProcessing}
            type="text"
            defaultValue={answer.description}
            onChange={(value) => updateAnswerDescription(value, idx)}
          />
        </InputWithCheckbox>
      ))}
      <div className="two-columns">
        <div className="flex">
          <span>Citation</span>
          <Input
            disabled={isProcessing}
            id={`citation_${question ? question.id : 'new'}`}
            type="text"
            defaultValue={citationData}
            onChange={(value) => { setCitationData(value) }}
          />
        </div>
        <div className="flex long">
          <span>Reason</span>
          <Input
            disabled={isProcessing}
            id={`reason_${question ? question.id : 'new'}`}
            type="text"
            defaultValue={reasonData}
            onChange={(value) => { setReasonData(value) }}
          />
        </div>
      </div>
      <div className="two-columns">
        <div className="flex half">
          <span>Category</span>
          <Input
            disabled={isProcessing}
            id={`cbpCategory_${question ? question.id : 'new'}`}
            type="text"
            defaultValue={cbpCategoryData}
            onChange={(value) => { setCbpCategoryData(value) }}
          />
        </div>
        <div className="flex half">
          <span>Category Name</span>
          <Input
            disabled={isProcessing}
            id={`cbpCategoryName_${question ? question.id : 'new'}`}
            type="text"
            defaultValue={cbpCategoryNameData}
            onChange={(value) => { setCbpCategoryNameData(value) }}
          />
        </div>
      </div>
    </StyledQuestionContainer>
  )
}