import React, { useContext } from "react";
import { StoreConextInterface } from "../types";
import { StoreContext } from "../contexts/storeContext";
import styled from "styled-components";
import {
  AppTitle,
  ContentBox,
  deviceSize,
} from "./common";
import { TestList } from "./tests-list";
import { FLASHCARD_ONLY, FULL_ACCESS, ROUTES, TESTS_AND_FLASHCARDS, TEST_ONLY } from "../constants";
import { Navigate } from "react-router-dom";

const StyledTest = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    width: 48%;
  }
  @media ${deviceSize.mobileL} {
    flex-direction: column;
    > div {
      width: 100%;
    }
  }
`;

export const PracticeTests = () => {
  const {
    testsFlashcards,
    access
  } = useContext<StoreConextInterface>(StoreContext);

  if (access === FLASHCARD_ONLY) return <Navigate to={ROUTES.FLASHCARDS} />
  else if (access === TEST_ONLY || access === FULL_ACCESS || access === TESTS_AND_FLASHCARDS) {
    return (
      <div>
        <AppTitle
          title='CBPrepCourse Practice Exams'
          description=''
        />
        <ContentBox>
          <StyledTest>
            <div>
              <TestList
                fullYear={false}
                testList={testsFlashcards.tests.chapter}
                listName={'Exams by Chapter'}
              />
            </div>
            <div>
              <TestList
                fullYear={true}
                testList={testsFlashcards.tests.fullYear}
                listName={'Actual Past Exams'}
              />
            </div>
          </StyledTest>
        </ContentBox>
      </div>
    );
  } else {
    return <></>
  }
};
