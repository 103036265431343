import React, { useContext, useEffect, useRef, useState } from "react"
import { Test, TestContextInterface } from '../types'
import { TEST_TYPES } from './admin-tests'
import { AdminTestQuestionsContainer } from "./admin-test-questions"
import { Button, almostWhite, buttonColor } from "./common"
import { AdminTestDetails } from './admin-test-details';
import styled from "styled-components"
import { FirebaseContext } from "../contexts/firebaseContext"
import { TestContext } from "../contexts/testContext"
import { AdminTestGeneral } from "./admin-test-general"


const StyledAdminTest = styled.div`

  .header{
    display: flex;
  }

  .button-row{
    display: flex;
    justify-content: space-around;
    margin-top: 1rem;

    button{
      outline: none;
      border: none;
      padding: 10px 20px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      font-size: 16px;
      cursor: pointer;
  
      &.active{
        background-color: ${almostWhite};
        cursor: default;
        border: 1px solid ${buttonColor};
        border-bottom: 1px solid ${almostWhite};
        margin-bottom: -1px;
      }
    }
  }

  .delete-button{
    float: right;
  }
`

const PAGES = {
  GENERAL: 'general',
  QUESTIONS: 'questions',
  DETAILS: 'details'
}
interface AdminTestProps {
  test: Test
  type: string
  goToTest: (type: string, test: string) => void
  isProcessing: boolean
  setIsProcessing: Function
}

export const AdminTest = (
  { test, type, goToTest, isProcessing, setIsProcessing }: AdminTestProps
) => {

  const { firebase } = useContext(FirebaseContext);
  const { loadTestsFlashcards } = useContext<TestContextInterface>(TestContext)

  const [name, setName] = useState<string>('')
  const [currentPage, setCurrentPage] = useState<string>(PAGES.QUESTIONS)

  const mountedRef = useRef<boolean>(false)

  useEffect(() => {
    if (test) {
      setName(type === TEST_TYPES.CAT ? test.categoryName : test.name)
    } else {
      setName('')
    }
  }, [test, type, setName])

  useEffect(() => { mountedRef.current = true }, [])

  const deleteTest = () => {
    setIsProcessing(true)
    firebase.doDeleteTest(type, test.id, () => {
      loadTestsFlashcards()
      goToTest(TEST_TYPES.CAT, '')
    })
  }

  return (
    <StyledAdminTest>
      <div className='header'>
      </div>
      <div className="button-row">
        <button
          className={currentPage === PAGES.GENERAL ? 'active' : ''}
          onClick={() => setCurrentPage(PAGES.GENERAL)}
        >General</button>
        <button
          className={currentPage === PAGES.QUESTIONS ? 'active' : ''}
          onClick={() => setCurrentPage(PAGES.QUESTIONS)}
        >Questions ({test.questions.length})</button>
        <button
          className={currentPage === PAGES.DETAILS ? 'active' : ''}
          onClick={() => setCurrentPage(PAGES.DETAILS)}
        >Details ({test.details.length})</button>
      </div>

      <div className="admin-sub-page">
        {
          currentPage === PAGES.QUESTIONS ?
            <AdminTestQuestionsContainer
              test={test}
              type={type}
              isProcessing={isProcessing}
              setIsProcessing={setIsProcessing}
            />
            : currentPage === PAGES.DETAILS ?
              <AdminTestDetails
                test={test}
                type={type}
                isProcessing={isProcessing}
                setIsProcessing={setIsProcessing}
              />
              : currentPage === PAGES.GENERAL ?
                <AdminTestGeneral
                  key={test.name + test.categoryName}
                  test={test}
                  testType={type}
                  isProcessing={isProcessing}
                  setIsProcessing={setIsProcessing}
                />
                : <></>
        }
      </div>

      <Button
        disabled={isProcessing}
        className='delete-button'
        red
        onClick={deleteTest}
      >
        Delete Test
      </Button>

    </StyledAdminTest>
  )
}