import moment from 'moment'
import React, { useState } from 'react'
import { SKUS } from '../constants'
import { Button, Input, SelectDropdown } from './common'


interface AdminUsersAddNewProps {
  addNewUser: Function,
  expirationDates: number[]
}

export const AdminUsersAddNew = (props: AdminUsersAddNewProps) => {

  const [newUserEmail, setNewUserEmail] = useState<string>('')
  const [newUserSku, setNewUserSku] = useState<string>(SKUS[0])
  const [newUserExpiration, setNewUserExpiration] = useState<number>(props.expirationDates[0])


  const validExpirationDates = props.expirationDates.filter(x => moment(x * 1000) > moment())
  const stringExpirationDates = validExpirationDates.map(x => x.toString())
  return (
    <table>
      <thead>
        <tr>
          <th>New User Email</th>
          <th>SKU</th>
          <th>Expiration</th>
          <th>Add New</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <Input
              type="text"
              onChange={(value: string) => setNewUserEmail(value)}
            />
          </td>
          <td>
            <SelectDropdown
              defaultValue={newUserSku}
              options={SKUS}
              onChange={(value: string) => setNewUserSku(value)}
            />
          </td>
          <td>
            <SelectDropdown
              defaultValue={newUserExpiration.toString()}
              options={stringExpirationDates}
              optionTexts={validExpirationDates.map(x => moment(x * 1000).format('MMM D YYYY'))}
              onChange={(value: string) => {
                setNewUserExpiration(
                  parseInt(value)
                )
              }}
            />
          </td>
          <td>
            <Button secondary onClick={() => props.addNewUser(newUserEmail, newUserSku, newUserExpiration)}>Add New User</Button>
          </td>
        </tr>
      </tbody>
    </table>
  )
}