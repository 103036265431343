import React from "react"
import styled from "styled-components"
import { css } from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import { CategoryVideos, SeenVideo, VideoData } from "../types"

const buttonHeight = 20

const StyledVideosCollapsableList = styled.div`
  border: 1px solid rgba(0,0,0,.125);
  border-radius: .25rem;
`

const StyledVideosCollapsableListHeader = styled.div`
  padding: .75rem 1.25rem;
  background-color: rgba(0,0,0,.03);
  border-bottom: 1px solid rgba(0,0,0,.125);

  &:hover{
    cursor: pointer;
  }

  >div:nth-child(1){
    font-size: 16px;
    padding: 9px 13px;
    background-color: rgb(239, 239, 239);
    border-radius: .25rem;
    text-align: center;
  }
`

interface StyledCollapsableListContentProps {
  height: number
  open: boolean
}

const StyledVideosCollapsableListContent = styled.div`

    display: flex;
    flex-flow: column;
    align-items: flex-start;
    transition: 
      height .35s ease-in-out,
      padding .35s ease-in-out;
    overflow: hidden;
    padding: 0 20px;
    height: 0;

    ${((props: StyledCollapsableListContentProps) => props.open && css`
      height: ${(props: StyledCollapsableListContentProps) => props.height + 'px'};
      padding: 20px;
    `)}

    button {
      height: ${buttonHeight + 'px'};
      background-color: Inherit;
      border: none;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 100%;
      
      &:focus {
        // outline: 0 !important;
      }

      &:hover{
        cursor: pointer;
      }

      > svg {
        margin-right: 1rem;
      }
    }

    a {
      display: flex;
      padding-left: 35px;
      text-transform: uppercase;
      >img {
        padding-right: 10px;
      }
    }
`

interface VideosCollapsableListProps {
  catagoryIndex: number
  videoListData: CategoryVideos
  userVideoData: SeenVideo[]
  currentlyOpenedCollapsable: number
  setCurrentlyOpenedCollapsable: Function
  setVideoAndPlay: Function
  setUserSeenVideo: Function
}

export const VideosCollapsableList = (props: VideosCollapsableListProps) => {
  const listContentHeight = buttonHeight * props.videoListData.videos.length + 20
  const open = props.currentlyOpenedCollapsable === props.catagoryIndex

  return (
    <StyledVideosCollapsableList>
      <StyledVideosCollapsableListHeader
        open={open}
        onClick={() => props.setCurrentlyOpenedCollapsable(
          props.catagoryIndex === props.currentlyOpenedCollapsable ? -1 : props.catagoryIndex
        )}
      >
        <div>
          {props.videoListData.title}
        </div>
      </StyledVideosCollapsableListHeader>
      <StyledVideosCollapsableListContent
        height={listContentHeight}
        open={open}
      >
        {props.videoListData.videos.map((video: VideoData, i) => (
          <div key={`${video.id}_${i}`}>
            <button
              key={video.id}
              onClick={() => props.setVideoAndPlay(video)}
            >
              {
                Boolean((props.userVideoData.find((v: SeenVideo) => v.id === video.id) || { seen: false }).seen) ?
                  (<FontAwesomeIcon icon={faPlay} color="#07F905" />) :
                  (<FontAwesomeIcon icon={faPlay} />)
              }
              {' ' + video.title}
            </button>
            {video.pdfs && video.pdfs.map((pdf, i) =>
              <a key={`${pdf.title}_${i}`} rel="noopener noreferrer" target="_blank" href={require(`../assets/extra/${pdf.file}`)}><img alt="pdf icon" src={require(`../assets/pdficon_small.gif`)} />{pdf.title}</a>
            )}
          </div>
        ))}
      </StyledVideosCollapsableListContent>
    </StyledVideosCollapsableList>
  )
}