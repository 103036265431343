import React from 'react';
import styled from 'styled-components';

type Timebreakdown = { hours: string, minutes: string, seconds: string };

const StyledTestCountDownTimer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 1rem;
    min-width: 10rem;
    align-items: flex-start;

    >div:nth-child(1){
        font-size: 1rem;
    }
    >div:nth-child(2){
        font-size: 2rem;
    }
`
export const TestCountDownTimer = ({ totalSeconds }: { totalSeconds: number }) => {

    const toTwoDigits = (num: number): string => {
        return `0${num}`.slice(-2);
    }
    const toHMS = (secondsRemaining: number): Timebreakdown => {
        let hours = Math.floor(secondsRemaining / 3600);
        let minutes = Math.floor((secondsRemaining - (hours * 3600)) / 60);
        let seconds = secondsRemaining - (hours * 3600) - (minutes * 60);
        return { hours: toTwoDigits(hours), minutes: toTwoDigits(minutes), seconds: toTwoDigits(seconds) };
    }

    const { hours, minutes, seconds } = toHMS(totalSeconds);

    return (
        <>
            {totalSeconds >= 0 && (
                <StyledTestCountDownTimer>
                    <div>
                        Time Remaining:
                    </div>
                    <div>
                        {hours} : {minutes} : {seconds}
                    </div>
                </StyledTestCountDownTimer>
            )}
        </>
    );
};