import React, { useContext, useMemo } from 'react'
import styled from 'styled-components'
import { StoreContext } from '../contexts/storeContext'
import { StoreConextInterface, Test, UserTest } from '../types'
import { Link } from 'react-router-dom'
import { ROUTES } from '../constants'


interface StyledProgressBarProps {
  userTestExisits: boolean
  percentageCorrect?: number
  percentageMissed?: number
  questionsRemaining?: number
  large?: boolean
}

const StyledTestName = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #0C0C20;
  white-space: pre-wrap;
`

const StyledProgressBar = styled.div`
  display: flex;
  flex-direction: row;  
  color: #fff;
  background-color: #e9ecef;
  border-radius: ${(props: StyledProgressBarProps) =>
    props.large ? `0.75rem` : '0.5rem'
  };
  overflow: hidden;
  
  div{
    height:100%;
    transition: width .5s ease-in-out
  }

  >div:nth-child(1){
    width: 100%;

    >div:nth-child(1){
      display: flex;
      justify-content: row;

      font-size: .75rem;
      line-height: 1rem;
      text-align: center;

      >div{
        height: ${(props: StyledProgressBarProps) =>
    props.large ? `2rem` : '1rem'
  }
      }

      >div:nth-child(1){
        background-color: #28a745;
        width: ${(props: StyledProgressBarProps) =>
    props.percentageCorrect ? `${props.percentageCorrect}%` : 0
  };
        margin-right: ${(props: StyledProgressBarProps) =>
    props.percentageCorrect ? '0.25rem' : '0'};
        border-radius: ${(props: StyledProgressBarProps) =>
    props.percentageCorrect === 100 ? `0 0.5rem 0.5rem 0` : '0'
  };
      }
      >div:nth-child(2) {
        background-color: #dc3545;
        width: ${(props: StyledProgressBarProps) =>
    props.percentageMissed ? `${props.percentageMissed}%` : 0
  };
      margin-right: ${(props: StyledProgressBarProps) =>
    props.percentageMissed ? '0.25rem' : '0'};
      border-radius: ${(props: StyledProgressBarProps) =>
    !props.questionsRemaining ? `0 0.5rem 0.5rem 0` : '0'
  };
      }
      >div:nth-child(3){
        background-color: rgba(120, 119, 110, 0.3);
        width: ${(props: StyledProgressBarProps) =>
    props.percentageCorrect !== undefined && props.percentageMissed !== undefined ?
      `${100 - (props.percentageCorrect + props.percentageMissed)}%` :
      `100%`
  };
      }
    }
  }
  >div:nth-child(2){
    background-color: ${(props: StyledProgressBarProps) =>
    props.userTestExisits ? '#ffc107' : '#b39a51'
  };

    cursor: ${(props: StyledProgressBarProps) =>
    props.userTestExisits ? 'pointer' : 'inherit'
  };
    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.5;
  }
`

const StyledTestItem = styled.div`
  background: rgba(217, 217, 217, 0.3);
  padding: 0.5rem 0.75rem;
`
const StyledTestItemBorder = styled.div`
  height: 1rem;
  border-bottom: 2px solid #D9D9D9;
`
const StyledTestButton = styled(Link)`
  text-decoration: none;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  text-align: right;
  color: #3E5489;
`
const StyledHeaderSection = styled.div`
  display: grid;
  grid-template-columns: 3fr auto 1fr;
  grid-gap: 4px;
  white-space: nowrap;
  line-height: 27px;
`

const StyledQuestionCount = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #313A4A;
  align-self: flex-start;
`
const StyledUserStatus = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #313A4A;
  padding: 0.25rem 0;
`
const StyledClear = styled.div`
  cursor: pointer;
`
const StyledRollup = styled.div`
  margin: 0 0.5rem 1rem;
`
const StyledRollupHeader = styled.div`
  display: flex;
  justify-content: space-between;
`
interface TestProgressBarProps {
  testId?: number
  userTestTimestamp?: number
  percentageCorrect?: number
  percentageMissed?: number
  questionsRemaining?: number
  large?: boolean
}

type UserTestsRollup = { total: number, correctPct: number, wrongPct: number, incomplete: number, answered: number };

const calculateCategoryTestRollup = (userTests: UserTest[], totalQuestions: number): UserTestsRollup => {
  const userData = userTests.filter(u => u);
  const data = userData.reduce((p, r) => {
    return {
      correct: p.correct + r.correctAnswers,
      wrong: p.wrong + r.wrongAnswers,
      incomplete: p.incomplete - r.questionsAnswered,
      answered: p.answered + r.questionsAnswered,
      total: totalQuestions
    }
  },
    { total: 0, correct: 0, wrong: 0, incomplete: totalQuestions, answered: 0 }
  );

  return {
    correctPct: data.correct ? (data.correct / totalQuestions) * 100 : 0,
    wrongPct: data.wrong ? (data.wrong / totalQuestions) * 100 : 0,
    incomplete: data.incomplete ? (data.incomplete / totalQuestions) * 100 : 0,
    answered: data.answered,
    total: totalQuestions,
  }
}

export const CategoryTestProgressBar = ({ userTests, tests }: { userTests: UserTest[], tests: Test[] }) => {
  const { removeTestResult } = useContext<StoreConextInterface>(StoreContext)
  const filteredTests = tests.filter(t => !t.isHidden && !!t.categoryName);
  const filteredTestIds = filteredTests.map(t => t.id);
  const totalQuestions = filteredTests.reduce((p, t) => p + t.questions.filter(q => !q.hide).length, 0);
  const filteredUserTests = userTests.filter(t => filteredTestIds.includes(t.testId))

  const data = useMemo(() =>
    calculateCategoryTestRollup(filteredUserTests, totalQuestions)
    , [filteredUserTests.length])

  const userStatsLabel = useMemo(() => {
    if (!data) return "";
    return `${data.correctPct.toFixed(1)}% correct | ${data.wrongPct.toFixed(1)}% incorrect | ${data.incomplete.toFixed(1)}% incomplete | ${data.answered} or ${data.total} Answered`
  }, [data])

  const removeAllTestResult = () => {
    filteredTests.map(f => removeTestResult(f.id))
  }
  return (
    <StyledRollup>
      <StyledRollupHeader>
        <StyledTestName>Overall Stats</StyledTestName>
        <StyledClear
          onClick={
            () => removeAllTestResult()
          }
        >
          Clear All Answers
        </StyledClear>
      </StyledRollupHeader>
      <StyledUserStatus>{userStatsLabel}</StyledUserStatus>

      <TestProgressBar large={true} testId={0}
        userTestTimestamp={0}
        percentageCorrect={data.correctPct}
        percentageMissed={data.wrongPct}
        questionsRemaining={data.incomplete} />
    </StyledRollup>
  )
}

export const TestProgressBar = (props: TestProgressBarProps) => {
  return (
    <StyledProgressBar
      large={props.large}
      userTestExisits={!!props.userTestTimestamp}
      percentageCorrect={props.percentageCorrect}
      percentageMissed={props.percentageMissed}
      questionsRemaining={props.questionsRemaining}
    >
      <div>
        <div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </StyledProgressBar>
  )
}

interface TestListItemProps {
  test: Test
  userTestData: UserTest[]
  fullYear: boolean
}

export const TestListItem = (props: TestListItemProps) => {
  const { removeTestResult } = useContext<StoreConextInterface>(StoreContext)
  const test = props.test
  const userTest = props.userTestData.find(x => x.testId === test.id)

  const userStatsLabel = useMemo(() => {
    if (!userTest) return "";
    if (userTest?.correctPct !== 100) {
      return `${userTest.correctPct}% correct | ${userTest.wrongPct}% incorrect | ${100 - (userTest.correctPct + userTest.wrongPct)}% incomplete`
    }
    return "100% Correct!"

  }, [userTest?.correctPct, userTest?.wrongPct])

  const buttonText = useMemo(() => {
    if (userTest?.questionsAnswered === test?.questions?.length) {
      return "Review"
    }
    if ((userTest?.questionsAnswered || 0) === 0) {
      return "Start Now";
    }
    return "Continue"
  }, [userTest?.questionsAnswered, userTest?.questionsTotal])

  const totalQuestionCount = useMemo(() => test?.questions?.filter(q => !q.hide).length, [test?.questions?.length])
  return (
    <StyledTestItem>
      <StyledHeaderSection>
        <StyledTestName>{props.fullYear ? test.name : test.categoryName}</StyledTestName>
        <StyledQuestionCount>{`${(userTest?.questionsAnswered || 0)} of ${totalQuestionCount} Answered`}</StyledQuestionCount>
        <StyledTestButton
          to={ROUTES.TAKE}
          state={{ test, userTest }}
        >
          {buttonText}
        </StyledTestButton>
      </StyledHeaderSection>
      {userTest &&
        <StyledUserStatus>
          <div>{userStatsLabel}</div>
          <StyledClear
            onClick={
              () => test.id && removeTestResult(test.id)
            }
          >
            Clear Answers
          </StyledClear>
        </StyledUserStatus>
      }
      {userTest?.questionsAnswered &&
        <TestProgressBar
          testId={test.id}
          userTestTimestamp={userTest && userTest.timestamp}
          percentageCorrect={userTest && userTest.correctPct}
          percentageMissed={userTest && userTest.wrongPct}
          questionsRemaining={userTest && userTest.questionsTotal - userTest.questionsAnswered}
        />}
      <StyledTestItemBorder />
    </StyledTestItem>
  )
}