import React, { useContext, useState } from "react";
import { ROUTES, MODULE_TITLES, FLASHCARD_ONLY, FULL_ACCESS, TEST_ONLY, TESTS_AND_FLASHCARDS } from "../constants";
import { StoreContext } from "../contexts/storeContext";
import { StyledNavigationSection, AppNavLink, Button, StyledAppMessage } from "../components/common";
import { useLocation } from "react-router-dom";
import { Welcome } from "../components/login";

export const NavigationSection = () => {
  const location = useLocation();
  const { loggedInUser, isAdmin, access, appError, appInfo, dissmissAppInfo, activeModule } = useContext(StoreContext);

  const [showMenu, setShowMenu] = useState<boolean>(false)

  const route = location.pathname;
  if (!loggedInUser) return <></>

  return (
    <StyledNavigationSection >
      <div className={`dim-screen ${!showMenu ? `hide` : ''}`} onClick={() => setShowMenu(false)}></div>
      <div className="hamburger-icon" onClick={() => setShowMenu(true)}>
        <div></div><div></div><div></div>
      </div>
      <div className={`nav-menu ${!showMenu && `hide`}`}>
        <div className="hamburger-icon" onClick={() => setShowMenu(false)}>
          <div></div><div></div><div></div>
        </div>
        <div className="nav-list">
          {access === FULL_ACCESS &&
            <AppNavLink
              disabled={!loggedInUser || !activeModule}
              active={route === ROUTES.DASHBOARD}
              page={ROUTES.DASHBOARD}
              text="Dashboard"
              hideMenu={() => setShowMenu(false)}
            />
          }
          {(access === FULL_ACCESS || access === TEST_ONLY || access === TESTS_AND_FLASHCARDS) &&
            <AppNavLink
              disabled={!loggedInUser || !activeModule}
              active={route === ROUTES.TESTS || route === ROUTES.TAKE}
              page={ROUTES.TESTS}
              text="Practice Exams"
              hideMenu={() => setShowMenu(false)}
            />
          }
          {access === FULL_ACCESS &&
            <AppNavLink
              disabled={!loggedInUser || !activeModule}
              active={route === ROUTES.VIDEOS}
              page={ROUTES.VIDEOS}
              text="Lecture Videos"
              hideMenu={() => setShowMenu(false)}
            />
          }
          {access === FULL_ACCESS &&
            <AppNavLink
              disabled={!loggedInUser || !activeModule}
              active={route === ROUTES.CLASSNOTES}
              page={ROUTES.CLASSNOTES}
              text="Class Notes"
              hideMenu={() => setShowMenu(false)}
            />
          }
          {access === FULL_ACCESS &&
            <AppNavLink
              disabled={!loggedInUser || !activeModule}
              active={route === ROUTES.EXTRAINFO}
              page={ROUTES.EXTRAINFO}
              text="Extra Information"
              hideMenu={() => setShowMenu(false)}
            />
          }
          {(access === FULL_ACCESS || access === FLASHCARD_ONLY || access === TESTS_AND_FLASHCARDS) &&
            <AppNavLink
              disabled={!loggedInUser || !activeModule}
              active={route === ROUTES.FLASHCARDS}
              page={ROUTES.FLASHCARDS}
              text="Flashcards"
              hideMenu={() => setShowMenu(false)}
            />
          }
          {isAdmin &&
            <AppNavLink
              disabled={!loggedInUser}
              active={route === ROUTES.ADMIN}
              page={ROUTES.ADMIN}
              text="Admin"
              hideMenu={() => setShowMenu(false)}
            />
          }
        </div>
        <div className="logout">
          <Welcome />
        </div>
      </div>
      {appError && (
        <StyledAppMessage>
          <span>{appError}</span>
          <Button onClick={() => dissmissAppInfo()}>X</Button>
        </StyledAppMessage>
      )}
      {appInfo && (
        <StyledAppMessage isInfo>
          <span>{appInfo}</span>
          <Button onClick={() => dissmissAppInfo()}>X</Button>
        </StyledAppMessage>
      )}
    </StyledNavigationSection>
  );
};

export const ModulesNavigation = () => {
  const { loggedInUser, modules, changeActiveModule, activeModule } = useContext(StoreContext);
  if (!loggedInUser || activeModule === null || modules.length < 2) return null;
  return (
    <StyledNavigationSection>
      {modules.map((m: string) => (
        <Button selected={activeModule === m} key={m} onClick={() => changeActiveModule(m)}>
          {MODULE_TITLES[m]}
        </Button>
      ))}
    </StyledNavigationSection>
  );
};
