import React, { useContext, useEffect, useState } from "react";
import { StoreConextInterface, VideoData } from "../types";
import styled from "styled-components";
import { StoreContext } from "../contexts/storeContext";
import ReactPlayer from "react-player";
import { deviceSize, smallText, } from "./common";
import { VideosCollapsableList } from "./videos-collapsable-list";
import { TEST_ONLY, ROUTES, FLASHCARD_ONLY, FULL_ACCESS, TESTS_AND_FLASHCARDS } from "../constants";
import { useLocation, Navigate } from "react-router-dom"


const StyledVideoPage = styled.div`
  font-size: ${smallText};
  display: flex;
  flex-flow: row;
  margin: 1rem 4rem;
  justify-content: space-between;

  > div {
    width: 48%;
  }

  > div:first-of-type {
    min-width: 300px;
  }

  @media ${deviceSize.tablet} {
    flex-flow: column;
    > div {
      width: 100%;
      min-width: unset;
      padding: 0;
    }
  }
`;

export const Videos = () => {

  const location = useLocation() as any;
  let resumeVideo: boolean
  if (location.state && location.state.resumeVideo) {
    resumeVideo = location.state.resumeVideo
  }

  const { addVideo, userVideoData, videos, access } = useContext<StoreConextInterface>(StoreContext);
  const [video, setVideo] = useState<VideoData>(videos[0].videos[0]);
  const [playing, setPlaying] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [currentlyOpenedCollapsable, setCurrentlyOpenedCollapsable] = useState<number>(0)

  const [resumed, setResumed] = useState<boolean>(false)

  useEffect(() => {
    if (resumeVideo && !resumed && userVideoData.length > 0) {
      let videoCat = videos.find(videoCat => videoCat.videos.some(video => video.id === userVideoData[userVideoData.length - 1].id))

      if (videoCat) {
        let videoData = videoCat.videos.find(video => video.id === userVideoData[userVideoData.length - 1].id)
        if (videoData) setVideo(videoData)
        setResumed(true)
      }
    }
  }, [videos, userVideoData])

  const setUserSeenVideo = (video: VideoData, seen: boolean) => {
    const value = { id: video.id, seen, timestamp: new Date().getTime() };
    addVideo(value);
  };

  const setVideoAndPlay = (video: VideoData) => {
    setVideo(video);
    setPlaying(true);
    setUserSeenVideo(video, true);
  };

  if (access === TEST_ONLY || access === TESTS_AND_FLASHCARDS) return <Navigate to={ROUTES.TESTS} />
  else if (access === FLASHCARD_ONLY) return <Navigate to={ROUTES.FLASHCARDS} />
  else if (access === FULL_ACCESS) {
    return (
      <StyledVideoPage>
        <div>
          <ReactPlayer
            width={"100%"}
            controls={true}
            url={video.link}
            playing={playing}
            onError={setError}
            muted={!playing}
          ></ReactPlayer>
          {error && <span>{error}</span>}
        </div>
        <div>
          {videos.map((videoListData, i) => (
            <VideosCollapsableList
              key={`${videoListData.title}_${i}`}
              catagoryIndex={i}
              videoListData={videoListData}
              userVideoData={userVideoData}
              currentlyOpenedCollapsable={currentlyOpenedCollapsable}
              setCurrentlyOpenedCollapsable={setCurrentlyOpenedCollapsable}
              setVideoAndPlay={setVideoAndPlay}
              setUserSeenVideo={setUserSeenVideo}
            />
          ))}
        </div>
      </StyledVideoPage>
    );
  }
  else {
    return <></>
  }
};