import React, { useContext, useState } from "react"
import { Navigate } from "react-router-dom"
import styled from "styled-components"
import { ROUTES } from "../constants"
import { StoreContext } from "../contexts/storeContext"
import { AdminTests } from "./admin-tests"
import { AdminUsers } from "./admin-users"
import { buttonColor, ContentBox } from "./common"
import { AdminFlashcards } from './admin-flashcards';

interface AdminPage {
  name: string
  component: React.FC
}

const ADMIN_PAGES: AdminPage[] = [
  { name: 'Users', component: AdminUsers },
  { name: 'Tests', component: AdminTests },
  { name: 'Flashcards', component: AdminFlashcards },
]

const StyledAdminContainer = styled.div`
.item-container{
  background-color: #f3f6fb;
  border: 1px solid #3e5489;
  padding: 1rem;

  .item{
      border: 1px solid #3e5489;
      padding: 0.5rem 0.75rem;
      margin: 0.5rem 0;
      border-radius: 0.25rem;
      background-color: white;

      .header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: .5rem;

        >div{
          font-weight: 700;
          font-size: 16px;

          button{
            margin-right: 0.25rem;
          }
        }
        button{
          font-size: 13px;
          line-height: 1;
          margin: 0px;
          margin-left: .2rem;
          margin-bottom: .1rem;
          padding: 7px;
          transition: all .15s ease-in-out;
        }
      }
    }
  }

  >div:nth-child(2){
    border-top: 1px solid ${buttonColor};
  }
`

const ButtonBar = styled.div`
  display: flex;
  justify-content: space-evenly;

  button{
    outline: none;
    border: none;
    padding: 10px 20px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-size: 16px;
    cursor: pointer;

    &.active{
      background-color: #fafafa;
      cursor: default;
      border: 1px solid ${buttonColor};
      border-bottom: 1px solid #fafafa;
      margin-bottom: -1px;
    }
  }
`

export const Admin = () => {
  const { isAdmin } = useContext(StoreContext)
  const [currentPage, setCurrentPage] = useState<AdminPage>(ADMIN_PAGES[0])

  if (!isAdmin) return <Navigate to={ROUTES.HOME} />
  else {
    return (
      <StyledAdminContainer>
        <ButtonBar>
          {ADMIN_PAGES.map(x => (
            <button
              className={currentPage.name === x.name ? 'active' : ''}
              key={`admin_nav_button_${x.name}`}
              onClick={() => setCurrentPage(x)}
            >
              {x.name}
            </button>
          ))}
        </ButtonBar>

        <ContentBox>
          <currentPage.component />
        </ContentBox>
      </StyledAdminContainer>
    )
  }
}