import React, { useContext, useEffect, useRef, useState } from 'react'
import { Detail, StoreConextInterface } from '../types'
import { Button, buttonColor, Input, SelectDropdown, TextArea } from './common'
import styled from 'styled-components';
import { TEST_TYPES } from './admin-tests';
import { StoreContext } from '../contexts/storeContext';

const imgFileNames = require('../assets/data/tests/img/imgFileNames.json')
const withBlankFilenames = ["", ...imgFileNames];

const StyledAdminTestDetailsItem = styled.div`
  border: 1px solid ${buttonColor};
  padding: 0.5rem 0.75rem;
  margin: 0.5rem 0;
  border-radius: 0.25rem;
  background-color: white;

  input{
    width: fill-available;
  }

  select,  input[type="text"]{
    padding: 2px 2px;
    border: 1px solid ${buttonColor};
    background-color: transparent;
    background-image: none;
    &:focus{
      outline:none;
    }
  }  

  button, select, textarea, input[type="text"]{
    font-size: 13px;
    line-height: 1;
    margin: 0px;
    margin-left: .2rem;
    margin-bottom: .1rem;

    &:focus{
      outline:none;
    }
  }  

  .flex{
    display: flex;
    justify-content: space-between;
    align-items: center;

    >div{
      display: flex;
      width: 30%;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      >div{
        width: fill-available;
      }
    }
  }
`

interface AdminTestDetailsItemProps {
  detail?: Detail
  doDeleteDetail?: (callback: Function) => void
  doSaveDetailChanges: (newDetail: Detail, callback?: Function) => void
  testId?: number
  isProcessing: boolean
  setIsProcessing: Function
  testType: string
}

export const AdminTestDetailsItem = ({ detail, doSaveDetailChanges, doDeleteDetail, testId, isProcessing, setIsProcessing, testType }: AdminTestDetailsItemProps) => {
  const { testsFlashcards } = useContext<StoreConextInterface>(StoreContext)
  const [descriptionData, setDescriptionData] = useState<string>(detail ? detail.title : '')
  const [startQuestionData, setStartQuestionData] = useState<number>(detail ? detail.startQuestion : 0)
  const [endQuestionData, setEndQuestionData] = useState<number>(detail ? detail.endQuestion : 0)
  const [fileNameData, setFileNameData] = useState<string>(detail ? detail.details : '')
  const [changesMade, setChangesMade] = useState<boolean>(false)
  const [tidData, setTidData] = useState<number>(detail ? detail.testId : testId ? testId : -1)

  const mountedRef = useRef(false)

  useEffect(() => {
    if (mountedRef.current) setChangesMade(true)
  }, [descriptionData, startQuestionData, endQuestionData, fileNameData, tidData])

  useEffect(() => { mountedRef.current = true }, [])

  const saveDetailChanges = () => {
    setIsProcessing(true)

    let newDetail = {} as Detail
    let valid = false
    if (detail) {
      valid = true
      newDetail = {
        ...detail,
        title: descriptionData,
        startQuestion: startQuestionData,
        endQuestion: endQuestionData,
        details: fileNameData,
        testId: tidData
      }
    } else if (testId) {
      valid = true
      newDetail = {
        title: descriptionData,
        startQuestion: startQuestionData,
        endQuestion: endQuestionData,
        details: fileNameData,
        testId: tidData
      }
    }

    if (valid) {
      doSaveDetailChanges(newDetail, () => {
        setIsProcessing(false)
        if (detail) setChangesMade(false)
      })
    }
  }

  const deleteDetail = () => {
    if (doDeleteDetail) {
      setIsProcessing(true)
      doDeleteDetail(() => {
        setIsProcessing(false)
      })
    }
  }

  return (
    <StyledAdminTestDetailsItem className='item'>
      <div className="header">

        {(detail && doDeleteDetail) ?
          <div>
            <Button
              red
              onClick={deleteDetail}
              disabled={isProcessing}
            >Delete</Button>
            Detail {startQuestionData} to {endQuestionData}
          </div>
          :
          <div>New Detail</div>
        }

        <Button
          secondary
          disabled={isProcessing || !changesMade || !fileNameData}
          onClick={saveDetailChanges}
        >Save Changes</Button>
      </div>
      <div>
        <TextArea
          disabled={isProcessing}
          rows={2}
          onChange={setDescriptionData}
          defaultValue={descriptionData}
        />
      </div>
      <div className="flex">

        <div>
          <span>Filename</span>
          <SelectDropdown
            disabled={isProcessing}
            options={withBlankFilenames}
            onChange={setFileNameData}
            defaultValue={fileNameData}
          />
        </div>
        <div>
          <span>Start</span>
          <Input
            disabled={isProcessing}
            type={'text'}
            onChange={(value) => { setStartQuestionData(parseInt(value)) }}
            defaultValue={startQuestionData.toString()}
          ></Input>
        </div>
        <div>
          <span>End</span>
          <Input
            disabled={isProcessing}
            type={'text'}
            onChange={(value) => { setEndQuestionData(parseInt(value)) }}
            defaultValue={endQuestionData.toString()}
          ></Input>
        </div>
        {
          testType === TEST_TYPES.CAT &&
          <div>
            <span>Test</span>
            <SelectDropdown
              disabled={isProcessing}
              options={testsFlashcards.tests.fullYear.map(x => x.id.toString())}
              optionTexts={testsFlashcards.tests.fullYear.map(x => x.name)}
              onChange={(v: string) => setTidData(parseInt(v))}
              defaultValue={tidData.toString()}
            />
          </div>
        }
      </div>
    </StyledAdminTestDetailsItem>
  )
}
