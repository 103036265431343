import moment from "moment"
import React, { useState } from "react"
import styled from "styled-components"
import { SKUS } from "../constants"
import { Button, SelectDropdown } from "./common"
import { User } from './admin-users'
import { useContext } from "react"
import { StoreContext } from "../contexts/storeContext"

const StyledAdminUsersListItem = styled.tr`

`

interface AdminUsersListItemProps {
  user: User
  updateUserCbpSku: Function
  updateUserExpirationDate: Function
  expirationDates: number[]
}

export const AdminUsersListItem = (
  { user, updateUserCbpSku, updateUserExpirationDate, expirationDates }: AdminUsersListItemProps
) => {
  const { sendPasswordResetEmail, deleteUserData } = useContext(StoreContext);
  const [isProcessing, setIsProcessing] = useState<boolean>(false)

  // const [addModule, setAddModule] = useState<string>(user.module.moduleName);

  const validExpirationDates = expirationDates.filter(x => moment(x * 1000) > moment())

  return (
    <StyledAdminUsersListItem key={user.email}>
      <td>{user.email}</td>
      <td>
        <SelectDropdown
          disabled={isProcessing}
          defaultValue={user.module.moduleName}
          options={SKUS}
          onChange={(value: string) => {
            setIsProcessing(true)
            updateUserCbpSku(user.email, value, user.module.moduleName, () =>
              setIsProcessing(false)
            )
          }}
        // onChange={(value: string) => setAddModule(value)}
        />
        {/* <Button
          disabled={addModule === user.module.moduleName}
          onClick={() => props.updateUserCbpSku(user.email, addModule, user.module.moduleName)}
        >Update SKU</Button> */}
      </td>
      <td>{moment(user.module.expiration.seconds * 1000).format('MMM D YYYY')}</td>
      <td>
        {
          <SelectDropdown
            disabled={isProcessing}
            defaultValue={'default'}
            options={['default', ...validExpirationDates.map(x => x.toString())]}
            onChange={(value: string) => {
              setIsProcessing(true)
              updateUserExpirationDate(user.email, user.module.moduleName, parseInt(value), () =>
                setIsProcessing(false)
              )
            }}
            optionTexts={['Update Expiration', ...validExpirationDates.map(x => moment(x * 1000).format('MMM D YYYY'))]}
            firstDisabled={true}
          />
        }
      </td>
      <td>
        <Button onClick={() => sendPasswordResetEmail(user.email)}>Send Password Reset Email</Button>
      </td>
      <td>
        <Button onClick={() => deleteUserData(user.email)}>Delete User</Button>
      </td>
    </StyledAdminUsersListItem>
  )
}