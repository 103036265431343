import {
  ActionType,
  TestContextInterface,
  ModuleData,
  Action,
  ModuleTestAction,
  ModuleFlashcardAction,
  ModuleDataAction,
  TestsFlashcards,
} from "../types";
import { Reducer, useReducer, useEffect, useState } from "react";
import React, { useContext } from "react";
import { FirebaseContext } from "./firebaseContext";

const testReducer: Reducer<ModuleData, Action> = (state: ModuleData, action: Action): ModuleData => {
  switch (action.type) {
    case ActionType.updateTest:
      const testAction = action as ModuleTestAction;
      const { test } = testAction.payload;
      const newState = {
        ...state,
        [testAction.payload.moduleName]: {
          test,
        },
      };
      return newState as ModuleData;
    case ActionType.updateFlashcards:
      const flashcardsAction = action as ModuleFlashcardAction;
      const { moduleName, flashcards } = flashcardsAction.payload;
      const result = {
        ...state,
        [moduleName]: {
          flashcards,
        },
      };
      return result as ModuleData;
    case ActionType.init:
      const moduleAction = action as ModuleDataAction;
      return moduleAction.payload.moduleData as ModuleData;
    default:
      throw new Error();
  }
};

export const TestContext = React.createContext<TestContextInterface>({
  moduleTests: {},
  setTestModules: () => { },
  updateTestFlashcardsModule: () => { },
  loadTestsFlashcards: () => { }
});

interface TestProviderProps {
  children: React.ReactNode;
}

function TestProvider(props: TestProviderProps) {
  const { firebase } = useContext(FirebaseContext);
  const [modules, setTestModules] = useState<string[]>([]);
  const [moduleTests, dispatch] = useReducer(testReducer, {});
  useEffect(() => {
    if (modules.length === 0) return;
    // when user logs in the modules will update need to fetch tests for the modules
    loadTestsFlashcards()
  }, [modules, firebase]);

  const loadTestsFlashcards = (callback?: Function) => {
    firebase.doRetreiveTests(modules, (moduleData: ModuleData) => {
      const dataAction = { type: ActionType.init, payload: { moduleData } };
      dispatch(dataAction);
      if (callback) callback()
    });
  }

  const updateTestFlashcardsModule = (moduleName: string, data: TestsFlashcards) => {
    if (!data || !moduleName) return;
    const dataAction = { type: ActionType.init, payload: { moduleData: { [moduleName]: data } } };
    dispatch(dataAction);
    firebase.doStoreTestFlashcards(moduleName, data);
  };

  return (
    <TestContext.Provider
      value={{
        moduleTests,
        setTestModules,
        updateTestFlashcardsModule,
        loadTestsFlashcards,
      }}
    >
      {props.children}
    </TestContext.Provider>
  );
}

export default TestProvider;
