import React, { useContext, useEffect, useState } from "react";
import { AccountContextInterface, TestContextInterface } from "../types";
import { DashboardSectionInfo } from "./dashboard";
import { AppTitle, ContentBox, deviceSize } from "./common";
import styled from "styled-components";
import { AccountContext } from "../contexts/accountContext";
import CalendarHeatmap from 'react-calendar-heatmap';
import 'react-calendar-heatmap/dist/styles.css';

const StyledTest = styled.div`
  display: flex;
  flex-direction: row;

  > div {
    width: 100%;
    padding: 0 15px;
  }
  @media ${deviceSize.tablet} {
    flex-direction: column;
    padding:0 25px;
    > div {
      width: 100%;
      padding: 0; 
    }
    > div:last-of-type {
      margin-top: 25px;
    }
  }
`;

const StyledNoUserActivity = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5rem;
    font-size: 1rem;
    font-weight: 800;
    width: 100%;
`
const StyledChartHeatmap = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`
const StyledChartContainer = styled.div`
    display: flex;
    width: 100%;
    height: 80%;
`

const StyledDayStats = styled.div`
    display:flex;
    justify-content: center;
    font-weight: 800;
`
type AccountActivities = {
    date: string, count: number
}

type AccountActivity = {
    date: string,
    count: number
    tests: number;
    videos: number;
}

const timestampToDay = (timestamp: number): string => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // add 1 to get 1-12 range
    const day = date.getDate();

    // create a key for the day object in the buckets object
    return `${year}-${month}-${day}`;
}

const groupTimestampsByDay = (timestamps: number[]): AccountActivities[] => {
    const buckets: { [day: string]: number[] } = {};

    timestamps.forEach(timestamp => {
        const key = timestampToDay(timestamp);

        // add the timestamp to the day object in the buckets object
        if (!buckets[key]) {
            buckets[key] = [];
        }
        buckets[key].push(timestamp);
    });
    const data: AccountActivities[] = [];
    Object.keys(buckets).forEach(key => {
        data.push({ date: key, count: buckets[key].length })
    });
    return data;
}

function isWithinTwoMonths(timestamp: number) {
    const current = new Date();
    const date = new Date(timestamp);
    const diffInMonths = (current.getFullYear() - date.getFullYear()) * 12 + current.getMonth() - date.getMonth();

    return diffInMonths <= 2;
}

function subtractTwoMonthsInSeconds(timestamp: number) {
    const monthInMilliseconds = 30 * 24 * 60 * 60 * 1000;
    const twoMonthsInSeconds = 2 * monthInMilliseconds / 1000;
    const newTimestamp = timestamp - twoMonthsInSeconds * 1000;

    return newTimestamp;
}

const NO_ACTIVITY = "No activity in the last 2 months";
const HOVER_DAY = "Hover over a day with activity";
const USER_NOT_FOUND = 'No activity data found for this user';
const DEFAULT_TEST_COUNT = 48; // TODO: update this when adding new tests

export const Account = ({ match }: any) => {
    // test account 0o2cEqeKCVZgrJZk46kgbVdA5LR2
    const { userId } = match?.params;
    const [errorMessages, setErrorMessages] = useState<string>();
    const [userDateActivity, setUserDateActivity] = useState<AccountActivity[]>([])
    const [userTakeTestCount, setUserTakeTestCount] = useState<number>(0)
    const [userTakeVideoCount, setUserTakeVideoCount] = useState<number>(0)
    const [userDateRange, setUserDateRange] = useState<{ start: string, end: string }>({ start: "", end: "" });
    const [userHasActivity, setUserHasActivity] = useState<boolean>(false);
    const [userSelectedActivity, setUserSelectedActivity] = useState<AccountActivity | null>(null);
    const {
        getUserTestData, loaded, videoCount
    } = useContext<AccountContextInterface>(AccountContext);

    useEffect(() => {
        if (!loaded && userId) {
            getUserTestData(userId, (error, userTestData) => {
                if (error) {
                    console.error(error);
                    setErrorMessages(error);
                    return;
                }
                if (!userTestData || Object.keys(userTestData).length === 0) {
                    setErrorMessages(USER_NOT_FOUND)
                    return;
                }
                const modules = Object.keys(userTestData);
                if (modules && modules.length > 0) {
                    const timestamps: number[] = []
                    const videoTimestamps: number[] = []
                    modules.forEach(module => {
                        const moduleData = userTestData[module];
                        if (moduleData) {
                            setUserTakeTestCount(moduleData.tests.length)
                            moduleData.tests.forEach(test => {
                                timestamps.push(test.timestamp);
                            })
                            setUserTakeVideoCount(moduleData.videos.length)
                            moduleData.videos.forEach(video => {
                                videoTimestamps.push(video.timestamp);
                            })
                        }
                    })
                    const sorted = [...timestamps, ...videoTimestamps].sort((a, b) => a - b);
                    const twoMonthsAgo = subtractTwoMonthsInSeconds(sorted[sorted.length - 1]);
                    const hasActivity = isWithinTwoMonths(sorted[sorted.length - 1]);
                    setUserHasActivity(hasActivity);
                    setUserDateRange({ start: timestampToDay(twoMonthsAgo), end: timestampToDay(sorted[sorted.length - 1]) })
                    const groupTestByDay = groupTimestampsByDay(timestamps).map((day: AccountActivities) => ({ ...day, tests: day.count, videos: 0 }));
                    const groupVideoByDay = groupTimestampsByDay(videoTimestamps).map((day: AccountActivities) => ({ ...day, videos: day.count, tests: 0 }));
                    const groupByDay = [...groupTestByDay, ...groupVideoByDay].reduce((p: { [day: string]: AccountActivity }, day: AccountActivity) => {
                        if (p[day.date]) {
                            p[day.date].count += day.count;
                            p[day.date].tests += day.tests;
                            p[day.date].videos += day.videos;
                        } else {
                            p[day.date] = day;
                        }
                        return p;
                    }, {});
                    const userDays = Object.values(groupByDay)
                    setUserDateActivity(userDays);
                }
            })
        }
    }, [])

    const DisplayUserActivity = () => {
        const message = userSelectedActivity
            ? <StyledDayStats>{`${userSelectedActivity.date} : Tests: ${userSelectedActivity.tests}, Videos: ${userSelectedActivity.videos}`}</StyledDayStats>
            : <StyledDayStats>{HOVER_DAY}</StyledDayStats>

        return (message)
    }

    return (
        <div>
            <AppTitle
                title='Student Account Activity'
                description=''
            />
            <ContentBox>
                <StyledTest>
                    <StyledChartContainer>
                        {userHasActivity
                            ? <StyledChartHeatmap>{(CalendarHeatmap as any)({
                                showMonthLabels: true,
                                showWeekdayLabels: true,
                                gutterSize: 1,
                                startDate: new Date(userDateRange.start),
                                endDate: new Date(userDateRange.end),
                                values: userDateActivity,
                                onMouseOver: (event: any, value: any) => {
                                    setUserSelectedActivity(value)
                                },
                                onMouseLeave: () => {
                                    setUserSelectedActivity(null)
                                },
                                classForValue: (value: any) => {
                                    if (!value) {
                                        return 'color-empty';
                                    }
                                    return `color-scale-${value.count}`;
                                }
                            })}<div><DisplayUserActivity /></div></StyledChartHeatmap>
                            : <StyledNoUserActivity>{errorMessages ? errorMessages : NO_ACTIVITY}</StyledNoUserActivity>}
                    </StyledChartContainer>
                    <div>
                        <DashboardSectionInfo
                            mainTitle={`Practice Exams`}
                            smallTitle={`Exam`}
                            readonly
                            info={{
                                started: userTakeTestCount,
                                notStarted: DEFAULT_TEST_COUNT - userTakeTestCount,
                                lastUserTest: null,
                                lastTest: null,
                            }}
                        />
                        <DashboardSectionInfo
                            mainTitle={`Lecture Videos`}
                            smallTitle={`Video`}
                            readonly
                            info={{
                                started: userTakeVideoCount,
                                notStarted: videoCount - userTakeVideoCount,
                            }}
                        />
                    </div>
                </StyledTest>
            </ContentBox>

        </div>
    );
};
