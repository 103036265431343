import React, { useState, useContext, useEffect } from "react";
import {
  LoggedInUser,
  StoreConextInterface,
  UserTest,
  SeenVideo,
  // Question,
  Flashcard,
  TestsFlashcards,
  CbprepSku,
} from "../types";
import { FirebaseContext } from "./firebaseContext";
import { UserContext } from "./userContext";
import { AdminAccounts, CBP_MODULE, getUserAccess } from "../constants";
import { TestContext } from "./testContext";
import { VIDEOS } from "../video-links";
import moment from "moment";
import { Timestamp } from "../components/admin-users";
import { User, UserInfo } from "firebase/auth";

interface StoreContextProps {
  children: React.ReactNode;
}

const blankTimestamp = {
  seconds: 0,
  nanoseconds: 0,
}

export const StoreContext = React.createContext<StoreConextInterface>({
  loginUser: (email: string, password: string) => { },
  logoutUser: () => { },
  loggedInUser: undefined,
  loginError: "",
  isLoading: false,
  modules: [],
  activeModule: null,
  changeActiveModule: () => { },
  isAdmin: false,
  appError: "",
  appInfo: "",
  videos: [],
  userTestData: [],
  userVideoData: [],
  testsFlashcards: {
    tests: {
      chapter: [],
      fullYear: []
    },
    flashcards: [],
  },
  removeTestResult: (categorId: number) => { },
  addTestResult: (userTest: UserTest) => { },
  addVideo: (video: SeenVideo) => { },
  removeVideo: (id: string) => { },
  sendPasswordResetEmail: (email: string) => { },
  deleteUserData: (email: string) => { },
  dissmissAppInfo: () => { },
  updateTestAndFlashcards: (moduleName: string, data: TestsFlashcards) => { },
  setError: Function,
  access: '',
  userExpiration: blankTimestamp,
});

function StoreProvider(props: StoreContextProps) {
  const { firebase } = useContext(FirebaseContext);
  const { moduleTests, setTestModules, updateTestFlashcardsModule } = useContext(TestContext);
  const {
    userModuleTestInfo,
    clearTestResult,
    addUserTest,
    addUserVideo,
    removeUserVideo,
    getUserTestData,
    userError,
  } = useContext(UserContext);
  const [loggedInUser, setLoggedInUser] = useState<LoggedInUser | undefined>(undefined);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [loginError, setLoginError] = useState<string>("");
  const [modules, setModules] = useState<string[]>([]);
  const [access, setAccess] = useState<string>('');
  const [activeModule, setActiveModule] = useState<string | null>(CBP_MODULE);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [appError, setError] = useState<string>("");
  const [appInfo, setInfo] = useState<string>("");
  const [userExpiration, setUserExpiration] = useState<Timestamp>(blankTimestamp)

  const changeActiveModule = (mod: string) => {
    setActiveModule(mod);
  };

  const logoutUser = () => {
    firebase.doSignOut();
    setLoggedInUser(undefined);
    setError("");
    setInfo("");
  };

  const addTestResult = (userTest: UserTest) => {
    addUserTest(activeModule, userTest);
  };

  const removeTestResult = (testId: number) => {
    clearTestResult(activeModule, testId);
  };

  const addVideo = (video: SeenVideo) => {
    addUserVideo(activeModule, video);
  };
  const removeVideo = (id: string) => {
    removeUserVideo(activeModule, id);
  };

  const sendPasswordResetEmail = (email: string) => {
    firebase.doPasswordReset(email);
    setInfo(`Reset password sent to ${email}`);
  };

  const deleteUserData = (email: string) => {
    setInfo(`Attemping to delete User ${email}`);
    try {
      firebase.doDeleteUserData(email, () => {
        setInfo(`User data deleted for ${email}`);
      });
    } catch(e){
      setError(`Not able to delete user ${email}`)
    }
  }
  const dissmissAppInfo = () => {
    setInfo("");
    setError("");
  };

  const updateTestAndFlashcards = (moduleName: string, testAndFlashcards: TestsFlashcards) => {
    setInfo("Test and/or Flashcards Saved!");
    updateTestFlashcardsModule(moduleName, testAndFlashcards);
    setTimeout(() => dissmissAppInfo(), 2000);
  };

  const validateExpirationDate = (expiration: Timestamp) => {
    const expirationTimeStamp = moment(expiration.seconds * 1000)
    const now = moment()
    return expirationTimeStamp.diff(now, 'days')
  }

  useEffect(() => {
    if (userError) setError("Data was not saved, check network connection")
  }, [userError])

  const loginUser = (email: string, password: string) => {
    setLoading(true);
    console.log('login as ', email)
    firebase.doSignInWithEmailAndPassword(
      email,
      password,
      (err: { message: string }, user: User | undefined) => {
        setLoading(false);
        if (err) {
          console.error(err);
          setError(err.message);
          return setLoginError(err.message);
        }
        if (!!!user) return setLoginError("User Not Found");
        setLoggedInUser({ user, uid: user.uid });
        firebase.doRetrieveUserCbpSku(user.uid, (userSku: CbprepSku, expiration: Timestamp) => {
          if (AdminAccounts.includes(user.uid)) {
            setIsAdmin(true);
            userSku = CbprepSku.SKU103;
            expiration = {
              seconds: new Date().getTime() + 100000,
              nanoseconds: 0,
            }
          } else {
            setIsAdmin(false);
          }

          if (userSku) {
            const GRACE_PERIOD = -3; // set to zero if no grace period
            // check expiration 
            const daysToExp = validateExpirationDate(expiration)
            if (!isAdmin && daysToExp < GRACE_PERIOD) {
              firebase.doSignOut()
              setLoggedInUser(undefined);
              setError('Your access to CBPrep has expired.')
            } else {
              setUserExpiration(expiration)
              setAccess(getUserAccess(userSku))
              setModules([CBP_MODULE]);
              setTestModules([CBP_MODULE]);
              setActiveModule(CBP_MODULE);
              getUserTestData(user.uid);

              if (daysToExp <= GRACE_PERIOD) {
                setInfo(`You are in a Grace Period, Access will be removed in a days`);
              } else if (daysToExp <= 14) {
                setInfo(`Access will expire in ${daysToExp} days`);
              }
            }
          } else {
            firebase.doSignOut()
            setLoggedInUser(undefined);
            setError('You do not have access to CBPrep.')
          }
        })
      },
    );
  };

  const store = {
    loginUser,
    logoutUser,
    loggedInUser,
    loginError,
    isLoading,
    modules,
    activeModule,
    changeActiveModule,
    isAdmin,
    appError,
    userTestData: (activeModule && userModuleTestInfo[activeModule] && userModuleTestInfo[activeModule].tests) || [],
    userVideoData: (activeModule && userModuleTestInfo[activeModule] && userModuleTestInfo[activeModule].videos) || [],
    testsFlashcards: (activeModule && moduleTests[activeModule]) || {
      tests: {
        chapter: [],
        fullYear: []
      },
      flashcards: [] as Flashcard[],
    },
    videos: VIDEOS,
    addTestResult,
    removeTestResult,
    addVideo,
    removeVideo,
    sendPasswordResetEmail,
    deleteUserData,
    appInfo,
    dissmissAppInfo,
    updateTestAndFlashcards,
    setError,
    access,
    userExpiration,
  };

  return <StoreContext.Provider value={store}>{props.children}</StoreContext.Provider>;
}

export default StoreProvider;
