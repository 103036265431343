import React, { useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components';
import { StoreContext } from '../contexts/storeContext';
import { StoreConextInterface, Test } from '../types';
import { AdminTest } from './admin-test';
import { AdminTestGeneral } from './admin-test-general';
import { Button, SelectDropdown } from './common';

export const TEST_TYPES = {
  CAT: 'Category Test',
  FULL: 'Full Exam'
}

const StyledTestHeaderBar = styled.div`
  display: flex;
  justify-content: space-between;
`

export const AdminTests = () => {
  const {
    testsFlashcards
  } = useContext<StoreConextInterface>(StoreContext)

  const [isProcessing, setIsProcessing] = useState<boolean>(false)

  const [filteredTests, setFilteredTests] = useState<Test[]>([])
  const [currentTestType, setCurrentTestType] = useState<string>(TEST_TYPES.CAT)
  const [creatingNewTest, setCreatingNewTest] = useState<boolean>(false)
  const [name, setName] = useState<string>('')

  const highestTestId = useRef<number>(0)
  const test = useRef<Test>()
  const mounted = useRef<boolean>(false)

  useEffect(() => {
    if (mounted.current) {
      if (testsFlashcards.tests.chapter.length > 0 && currentTestType === TEST_TYPES.CAT) {
        setFilteredTests(testsFlashcards.tests.chapter)
        if (!creatingNewTest)
          setName(testsFlashcards.tests.chapter[0].categoryName)
      } else if (testsFlashcards.tests.chapter.length > 0 && currentTestType === TEST_TYPES.FULL) {
        setFilteredTests(testsFlashcards.tests.fullYear)
        if (!creatingNewTest)
          setName(testsFlashcards.tests.fullYear[0].name)
      }
    }
  }, [currentTestType])

  useEffect(() => {
    if (testsFlashcards.tests.chapter.length > 0 && currentTestType === TEST_TYPES.CAT) {
      setFilteredTests(testsFlashcards.tests.chapter)
      if (name === '' && !creatingNewTest) {
        setName(testsFlashcards.tests.chapter[0].categoryName)
      }
    } else if (testsFlashcards.tests.fullYear.length > 0 && currentTestType === TEST_TYPES.FULL) {
      setFilteredTests(testsFlashcards.tests.fullYear)
      if (name === '' && !creatingNewTest) {
        setName(testsFlashcards.tests.fullYear[0].name)
      }
    }
    // TODO: find a better way of generating new test ID. (generate random id?) 
    // NOTE: id not really used, could be scrapped 
    testsFlashcards.tests.chapter.map(x => {
      if (x.id > highestTestId.current) highestTestId.current = x.id
    })
    testsFlashcards.tests.fullYear.map(x => {
      if (x.id > highestTestId.current) highestTestId.current = x.id
    })

  }, [testsFlashcards])

  useEffect(() => {
    mounted.current = true
  }, [])

  const goToTest = (type: string, name: string) => {
    setCurrentTestType(type)
    setCreatingNewTest(false)
    setName(name)
  }

  if (creatingNewTest || name === '') {
    test.current = undefined
  } else {
    const foundTest = filteredTests.find(x =>
      currentTestType === TEST_TYPES.CAT
        ? x.categoryName === name : x.name === name
    )
    if (foundTest) {
      test.current = foundTest
    }
  }

  return (
    <div>
      <StyledTestHeaderBar>
        <div>
          <SelectDropdown
            defaultValue={currentTestType}
            options={[TEST_TYPES.CAT, TEST_TYPES.FULL]}
            onChange={(value: string) => {
              setCurrentTestType(value)
              setCreatingNewTest(false)
            }}
          />
          <SelectDropdown
            key={currentTestType}
            defaultValue={name}
            options={['', ...filteredTests.map(x => currentTestType === TEST_TYPES.CAT ? x.categoryName : x.name)]}
            optionTexts={['Select Test', ...filteredTests.map(x => currentTestType === TEST_TYPES.CAT ? x.categoryName : x.name)]}
            onChange={(value: string) => {
              setName(value)
              setCreatingNewTest(false)
            }}
          />
        </div>
        <div>
          <Button onClick={() => {
            setName('')
            setCreatingNewTest(true)
          }}>Create New Test</Button>
        </div>
      </StyledTestHeaderBar>
      {
        test.current ?
          <AdminTest
            test={test.current}
            type={currentTestType}
            goToTest={goToTest}
            isProcessing={isProcessing}
            setIsProcessing={setIsProcessing}
          />
          :
          creatingNewTest ?
            <AdminTestGeneral
              goToTest={goToTest}
              highestTestId={highestTestId.current}
              isProcessing={isProcessing}
              setIsProcessing={setIsProcessing}
            />
            : <></>
      }
    </div>
  )
}