import React, { useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { StoreContext } from '../contexts/storeContext'
import { Flashcard, StoreConextInterface } from '../types'
import { AdminFlashcardItem } from './admin-flashcard-item';
import { Button, Input, SelectDropdown } from './common';
import { sortCategories } from '../helpers/structures';


const StyledAdminFlashcards = styled.div`
  .header{
    display: flex;
    justify-content: space-between;
  }
`

interface FlashcardGroup {
  groupName: string
  flashcards: Flashcard[]
}

export const AdminFlashcards = () => {
  const { testsFlashcards } = useContext<StoreConextInterface>(StoreContext)
  const [groupedFlashcards, setGroupedFlashcards] = useState<FlashcardGroup[]>([])
  const [selectedGroupName, setSelectedGroupName] = useState<string>('')
  const [creatingNew, setCreatingNew] = useState<boolean>(false)
  const [creatingNewTopic, setCreatingNewTopic] = useState<boolean>(false)

  const selectedGroup = useRef<FlashcardGroup>()
  const highestId = useRef(-1)

  useEffect(() => {
    let tempGroupedFlashcards: FlashcardGroup[] = []
    testsFlashcards.flashcards.forEach(flashcard => {
      if (highestId.current < flashcard.id) highestId.current = flashcard.id
      const group = tempGroupedFlashcards.find(x => x.groupName === flashcard.t)
      if (group) {
        group.flashcards.push(flashcard)
      } else {
        tempGroupedFlashcards.push({ groupName: flashcard.t, flashcards: [flashcard] })
      }
    });
    if (tempGroupedFlashcards.length > 0) {
      setCreatingNew(false)
      setGroupedFlashcards(tempGroupedFlashcards)
      if (selectedGroupName === '') {
        setSelectedGroupName(tempGroupedFlashcards[0].groupName)
      }
    }
  }, [testsFlashcards])

  useEffect(() => {
    if (creatingNew) setCreatingNew(false)
  }, [selectedGroupName])

  useEffect(() => {
    if (creatingNewTopic) {
      setCreatingNew(false)
      setSelectedGroupName('')
    }
  }, [creatingNewTopic])

  const createNewTopic = () => {
    setCreatingNewTopic(false)
    setCreatingNew(true)
  }

  selectedGroup.current = groupedFlashcards.find(x => x.groupName === selectedGroupName)
  if (selectedGroup.current === undefined) {
    selectedGroup.current = { groupName: selectedGroupName, flashcards: [] }
  }

  return (
    <StyledAdminFlashcards>
      <div className="header">
        <div>
          <SelectDropdown
            defaultValue={selectedGroupName}
            onChange={setSelectedGroupName}
            options={sortCategories(groupedFlashcards.map(x => x.groupName))}
          />
          <Button
            onClick={() => setCreatingNewTopic(true)}
          >New Topic</Button>
        </div>
        <div>

          <Button
            secondary
            onClick={() => setCreatingNew(true)}
          >New Flashcard</Button>
        </div>
      </div>

      {creatingNewTopic &&
        <div>
          Topic Name: 
          <Input type='string' onChange={(v) => setSelectedGroupName(v)} />
          <Button onClick={() => { createNewTopic() }}>Create</Button>
        </div>
      }

      {selectedGroup.current &&
        <div className='item-container'>
          {creatingNew &&
            <AdminFlashcardItem
              highestId={highestId.current}
              t={selectedGroupName}
              groupedFlashcards={groupedFlashcards}
            />
          }
          {selectedGroup.current.flashcards.map(flashcard => (
            <AdminFlashcardItem
              key={flashcard.q}
              flashcard={flashcard}
              t={selectedGroupName}
              groupedFlashcards={groupedFlashcards}
            />
          ))}
        </div>
      }
    </StyledAdminFlashcards>
  )
}
