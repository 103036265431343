import React, { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ROUTES } from "../constants";
import { StoreContext } from "../contexts/storeContext";
import styled from 'styled-components';
import { deviceSize } from "../components/common";
import AuthenticatedRoute from "../firebase/authorized-route";
import { Home } from "../components/home";
import { Account } from "../components/Account";
import { Dashboard } from "../components/dashboard";
import { Videos } from "../components/videos";
import { Flashcards } from "../components/flashcards";
import { TakeTest } from "../components/take-test";
import { ClassNotes } from "../components/class-notes";
import { ExtraInfo } from "../components/extra-info";
import { Admin } from "../components/admin";
import { PracticeTests } from "../components/practice-tests";

interface StyledMainProps {
  loggedIn: boolean;
}

const StyledMain = styled.div<StyledMainProps>`
  @media ${deviceSize.tablet} {
    margin-top: ${(props: any) => (props.loggedIn ? '60px' : '0')};
  }
`;

export const MainSection: React.FC = () => {
  const { loggedInUser } = useContext(StoreContext);

  return (
    <StyledMain loggedIn={!!loggedInUser}>
      <Routes>
        <Route 
          path="/" 
          element={
            loggedInUser 
              ? <Navigate to={ROUTES.DASHBOARD} replace /> 
              : <Home />
          } 
        />
        <Route path="/account/:userId" element={<Account />} />
        <Route element={<AuthenticatedRoute />}>
          <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
          <Route path={ROUTES.VIDEOS} element={<Videos />} />
          <Route path={ROUTES.FLASHCARDS} element={<Flashcards />} />
          <Route path={ROUTES.TAKE} element={<TakeTest />} />
          <Route path={ROUTES.CLASSNOTES} element={<ClassNotes />} />
          <Route path={ROUTES.EXTRAINFO} element={<ExtraInfo />} />
          <Route path={ROUTES.ADMIN} element={<Admin />} />
          <Route path={ROUTES.TESTS} element={<PracticeTests />} />
        </Route>
      </Routes>
    </StyledMain>
  );
};