import { CbprepSku, ModuleTitles } from "./types";

export const AdminAccounts = ['NHyF8KcXGmMIpBvOzupMzGGbnAa2', 'idnZvJW1I1RympYRA7eS0MRK8dU2', 'HoNTbMWtx1YJLrDbfILHRXj52hp2'];

export const ROUTES = {
  HOME: "/",
  VIDEOS: "/videos",
  FLASHCARDS: "/flashcards",
  DASHBOARD: "/dashboard",
  TESTS: "/practice-tests",
  TAKE: "/take",
  CLASSNOTES: '/class-notes',
  EXTRAINFO: '/extra-information',
  ADMIN: '/admin',
};

export const COLLECTIONS = {
  USER_MODULES: "users",
  USER_DATA: "userdatacbp",
  MODULES: "modules",
  CATEGORY_TESTS: "cbpCategoryTest",
  FULL_EXAMS: "cbpFullExam",
  FLASHCARDS: "cbpFlashcards"
};

export const PASSABLE_LIMIT = 70;
export const MAX_USER_TESTS_ALLOWED = 40;
export const CBP_MODULE = "CBP_MODULE"

export const MODULE_TITLES: ModuleTitles = {
  [CBP_MODULE]: "Customs Broker Preparation Course",
};

export const ANSWER_LETTERS = ['A', 'B', 'C', 'D', 'E']


export const SKUS = [
  CbprepSku.SKU103, // everything online - videos, FC, test center
  CbprepSku.SKU104, // everything online - videos, FC, test center
  CbprepSku.SKU105, // only test center
  CbprepSku.SKU107, // only FC
  CbprepSku.SKU109, // tests and flashcards
]

export const FULL_ACCESS = 'FULL_ACCESS'
export const TEST_ONLY = 'TEST_ONLY'
export const FLASHCARD_ONLY = 'FLASHCARD_ONLY'
export const TESTS_AND_FLASHCARDS = 'TESTS_AND_FLASHCARDS'

export const getUserAccess = (userSku: CbprepSku) => {

  switch (userSku) {
    case CbprepSku.SKU103:
    case CbprepSku.SKU104:
      return FULL_ACCESS
    case CbprepSku.SKU105:
      return TEST_ONLY
    case CbprepSku.SKU107:
      return FLASHCARD_ONLY
    case CbprepSku.SKU109:
      return TESTS_AND_FLASHCARDS
    default:
      return ''
  }
}

export const monthNames = ["january", "february", "march", "april", "may", "june",
  "july", "august", "september", "october", "november", "december"
];
/*
cbprep SKUs:

Directives SKU100
CFR SKU101
Live Seminar Package SKU103
Internet learning package SKU104
Online Test Center SKU105
Flashcards SKU107
Harmonized Tariff Schedule SKU109

*/
