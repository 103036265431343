import React from "react";
import { Login } from "./login";
import styled from "styled-components";
import { deviceSize, AppTitle, infoBackgroundColor } from "./common";

const StyledHome = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  > div:nth-of-type(2) {
    display: flex;
    flex-flow: row;
    justify-content: space-evenly;
    width:100%;
    background-color: ${infoBackgroundColor};

    >div:first-of-type{
      width: 90%;
      max-width: 1400px;
      margin: 0 auto;
      padding: 60px 0 120px;

      @media ${deviceSize.tablet} {
        width: 100% important!;
        padding: 35px 10px 60px;
      }

    }

  }
  @media ${deviceSize.tablet} {
    > div:first-of-type {
      margin-bottom: 25px;
    }
    > div:nth-of-type(2) {
      flex-flow: column;
      justify-content: space-between;
      
      >div:first-of-type{
        margin-bottom: 25px;
      }
    }
  }
`;

export const Home = () => {
  return (
    <StyledHome>
      <AppTitle />
      <div>
        <Login />
      </div>
    </StyledHome>
  );
};
