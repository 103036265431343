import React, { useContext, useState } from 'react'
import { FirebaseContext } from '../contexts/firebaseContext';
import { TestContext } from '../contexts/testContext';
import { Detail, Test, TestContextInterface } from '../types';
import { AdminTestDetailsItem } from './admin-test-details-item';
import { Button } from './common';

interface AdminTestDetailsProps {
  test: Test | undefined
  type: string
  isProcessing: boolean
  setIsProcessing: Function
}

export const AdminTestDetails = ({ test, type, isProcessing, setIsProcessing }: AdminTestDetailsProps) => {
  const { firebase } = useContext(FirebaseContext);
  const { loadTestsFlashcards } = useContext<TestContextInterface>(TestContext)

  const [addingDetail, setAddingDetail] = useState<boolean>(false)

  const doSaveDetailChanges = (detailData: Detail, detailIndex: number, newDetail: boolean, callback?: Function) => {
    if (test) {
      let newTestData: Test = { ...test }
      if (newDetail) {
        newTestData.details = [detailData, ...newTestData.details]
      } else {
        newTestData.details[detailIndex] = detailData
      }
      try {
        firebase.doUpdateTest(type, newTestData, () => {
          loadTestsFlashcards(() => {
            if (addingDetail) setAddingDetail(false)
            if (callback) callback()
          })
        })
      } catch (error) {
        console.log('Error saving detail changes')
        if (callback) callback()
      }
    }
  }

  const doDeleteDetail = (detailIndex: number, callback: Function) => {
    if (test) {
      let newTestData: Test = { ...test }
      newTestData.details.splice(detailIndex, 1)
      try {
        firebase.doUpdateTest(type, newTestData, () => {
          setIsProcessing(false)
          loadTestsFlashcards()
        })
      } catch (error) {
        console.log('Error deleting detail')
        callback()
      }
    }
  }

  if (!test) return <></>
  return (
    <div className='item-container'>
      <Button
        disabled={isProcessing}
        onClick={() => setAddingDetail(!addingDetail)}
      >Add Detail</Button>
      <div>
        {addingDetail &&
          <AdminTestDetailsItem
            doSaveDetailChanges={(detailData, callback) => { doSaveDetailChanges(detailData, -1, true, callback) }}
            testId={test.id}
            isProcessing={isProcessing}
            setIsProcessing={setIsProcessing}
            testType={type}
          />
        }
        {test.details.map((detail, idx) => (
          <AdminTestDetailsItem
            key={`${idx}_${detail.details}_${detail.startQuestion}`}
            detail={detail}
            doSaveDetailChanges={(detailData, callback) => { doSaveDetailChanges(detailData, idx, false, callback) }}
            doDeleteDetail={(callback) => { doDeleteDetail(idx, callback) }}
            isProcessing={isProcessing}
            setIsProcessing={setIsProcessing}
            testType={type}
          />
        ))}
      </div>
    </div>
  )
}
