import React, { useContext } from 'react'
import styled from 'styled-components'
import { monthNames } from '../constants'
import { StoreContext } from '../contexts/storeContext'
import { StoreConextInterface, Test } from '../types'
import { CategoryTestProgressBar, TestListItem } from './tests-list-item'

const StyledTestList = styled.div`
  > div:nth-child(1){
    font-weight: 400;
    font-size: 18px;
    
    padding: 1rem 0.75rem;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    text-transform: uppercase;
    color: #0C0C20;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`
const StyledTestListContainer = styled.div`
  border-top: 2px solid rgba(120, 119, 110, 0.3);
  border-bottom: 2px solid rgba(120, 119, 110, 0.3);

  >div:last-child {
    div:nth-child(2) {
      border-bottom: none;
    }
  }
`
interface TestListProps {
  testList: Test[]
  listName: string
  fullYear: boolean
}

const parseDate = (mon: string, year: string): Date => {
  const d = new Date();
  d.setMonth(monthNames.indexOf(mon.toLowerCase()));
  d.setFullYear(parseInt(year));
  return d;
}
const getDateFromString = (t: Test): Date => {
  const first = t.name.split(" ");
  const month = first.length > 0 ? first[0] : undefined;
  const year = first.length > 0 ? first[1] : undefined;
  if (month && year) return parseDate(month, year)
  return new Date(); // backup
}
// hardcode order for now until UI is updated to support reordering categories
const categoryTestSortList = [34, 35, 36, 37, 38, 39, 40, 41, 42, 70, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 78, 53, 54, 73, 55, 56, 57, 58, 59, 60, 61, 62, 75, 33, 65, 67, 68, 74, 72, 66, 32];
const orderTests = (a: Test, b: Test): number => {
  if (a.categoryName) {
    const aId = categoryTestSortList.indexOf(a.id)
    const bId = categoryTestSortList.indexOf(b.id)
    if (aId < 0 || bId < 0) return 1;
    return aId < bId ? -1 : 1;
  }
  const first = getDateFromString(a);
  const second = getDateFromString(b);

  if (first.getTime() === second.getTime()) {
    // AM and PM, PM goes above
    return a.name.endsWith("AM") ? 1 : -1
  }
  return first.getTime() < second.getTime() ? 1 : -1;
}

export const TestList = (props: TestListProps) => {
  const { userTestData } = useContext<StoreConextInterface>(StoreContext)

  return (
    <StyledTestList>
      <div>{props.listName}</div>
      {!props.fullYear &&
        <CategoryTestProgressBar
          userTests={userTestData}
          tests={props.testList}
        />}
      <StyledTestListContainer>
        {props.testList.length !== 0 ?
          props.testList.filter(t => !t.isHidden)
            .sort(orderTests)
            .map((test) => (
              <TestListItem
                key={`testItem_${test.id}`}
                test={test}
                userTestData={userTestData}
                fullYear={props.fullYear}
              />
            ))
          :
          'Loading...'
        }
      </StyledTestListContainer>
    </StyledTestList >
  )
}