import React from 'react';
import styled from 'styled-components';
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StyledSideTabs = styled.div`
    display: flex;
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background-color: unset;
`

const StyleCheckbox = styled.span`
    align-self: center;
    color: #000000;
`

// make this div sticky to middle left of screen
const StyledSideLinks = styled.div`
  display: flex;
  flex-direction: column;
  color: #0C0C20;
  padding: 10px;
  width: 12rem;

  a {
    color: #0C0C20;
    text-decoration: none;
    font-size: 12px;
    line-height: 20px;
    padding: 10px;
    margin: 2px;
    display: block;
    border: 1px solid #0C0C20;
    border-radius: 10px 0 0 10px;
    background: linear-gradient(0deg, lightgray, transparent);

    &:hover {
      background-color: lightgray;
    }
  }
`

const footerLinks = [
    {
        name: 'CFR',
        link: require('../assets/data/testResources/2024CFR.pdf')
    },
    {
        name: 'ACE Business Rules and Processing Documents',
        link: require('../assets/data/testResources/ACEBRPDV12.pdf')
    },
    {
        name: 'ACE Entry Summary Instructions Version 2.4a',
        link: require('../assets/data/testResources/ACEEntrySummaryInstructions2.4a.pdf')
    },
    {
        name: 'Harmonized Tariff Schedule of the United States',
        link: require('../assets/data/testResources/2024HTS.pdf')
    },
    {
        name: 'Right to Make Entry (RTME) Directive 3530-002A',
        link: require('../assets/data/testResources/RightToMakeentry.pdf')
    },
]

export const SideTabs = ({ flagged, updateFlagged }: { flagged: boolean, updateFlagged: Function }) => {
    return (
        <StyledSideTabs>
            <StyleCheckbox>
                <input type='checkbox' checked={flagged} onChange={() => updateFlagged()} />
                Review
            </StyleCheckbox>
            <StyledSideLinks>
                <div>
                    {footerLinks.map(link => (
                        // increase size of icon
                        <a key={link.name} target={'_blank'} href={link.link}><FontAwesomeIcon size="lg" icon={faCaretLeft} />{' '}{link.name}</a>
                    ))}
                </div>
            </StyledSideLinks>
        </StyledSideTabs>
    )
}
